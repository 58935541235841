import {
	Layout,
	Breakpoints,
	Container,
	Text,
	Image,
	TextAlignment,
	TokenTextAppearance,
	CTA,
	ContainerGutter,
	styled,
} from '@volkswagen-onehub/components-core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTrackingManager, getTrackingForm } from 'src/feature-app';
import { OneFormState } from 'src/types';
import { ActionType } from './AutosubmitController';

const ThankYouAutosubmitWrapper = styled.div`
	position: relative;
	left: 0;
	margin-left: calc(var(--size-grid004) * -1);
	margin-right: calc(var(--size-grid004) * -1);

	& > div > div > div > div > div:first-child {
		width: 70%;
	}

	& img {
		object-fit: cover;
		height: 80vh;
	}

	@media (max-width: 959px) {
		& > div > div > div > div > div:first-child {
			width: 100%;
		}

		& img {
			height: auto;
		}
	}

	@media (max-width: 559px) {
		margin-left: calc(var(--size-grid002) * -1);
		margin-right: calc(var(--size-grid002) * -1);
	}
`;

interface Props {
	action: string;
}

export const ThankYouAutosubmit = (props: Props) => {
	const { action } = props;
	const { formInfo } = useSelector((state: OneFormState) => state);
	const { formResponse } = formInfo;

	const trackingManager = useTrackingManager();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
		dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: 0, name: 'ThankYou' } } });

		trackingManager.trackFormSubmissionSuccessMessageLoad(
			{
				contentId: undefined,
			},
			getTrackingForm(formResponse),
		);
	}, []);

	return (
		<ThankYouAutosubmitWrapper>
			<Layout
				appearance={{
					[Breakpoints.default]: [
						{ name: '.', columns: 2 },
						{ name: 'a', columns: 20 },
						{ name: '.', columns: 2 },
					],
					[Breakpoints.b960]: [
						{ name: '.', columns: 0 },
						{ name: 'a', columns: 22 },
						{ name: '.', columns: 2 },
					],
				}}
				allowOverflowingContent
			>
				<Container
					wrap={{ [Breakpoints.default]: 'always', [Breakpoints.b960]: 'never' }}
					horizontalAlign="center"
					verticalAlign="center"
					gutter={ContainerGutter.dynamic0100}
					shrinkContent
				>
					<Container stretchContent>
						<Image
							src="https://author-p117509-e1350031.adobeaemcloud.com/content/dam/evhub-sample/images/4-3/fullscreen4_3.jpg"
							alt="image"
							objectFit="contain"
						/>
					</Container>

					{action == ActionType.ClubVWUnsubscribeEmail ? (
						<Container wrap="always" gutter={ContainerGutter.dynamic0050} horizontalAlign={'center'}>
							<Text appearance={TokenTextAppearance.headline400} textAlign={TextAlignment.left} wordBreak>
								Hemos procesado tu solicitud de baja con éxito.
							</Text>
							<Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left} wordBreak>
								Si necesitas más información, no dudes en contactar con nosotros. ¡Hasta pronto!
							</Text>
						</Container>
					) : (
						<Container wrap="always" gutter={ContainerGutter.dynamic0050} horizontalAlign={'center'}>
							<Text appearance={TokenTextAppearance.headline400} textAlign={TextAlignment.left} wordBreak>
								Genial, ¡gracias!
							</Text>
							<Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left} wordBreak>
								A partir de ahora te informaremos de todas las novedades para que no te pierdas nada sobre nuestros 100%
								eléctricos, la Gama ID. ¡Disfruta de un presente electrizante!
							</Text>
							<CTA tag="a" emphasis="tertiary" href="#" aria-label="aria label for CTA">
								Descubre más sobre nuestros eléctricos
							</CTA>
						</Container>
					)}
				</Container>
			</Layout>
		</ThankYouAutosubmitWrapper>
	);
};
