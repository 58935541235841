import React, { useEffect, useState } from 'react';
import { Text } from '@volkswagen-onehub/components-core';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import { FormDataGroup, FormInfo, IdPerson, IdVehicle, IdDealer, IdOrder, OneFormState, Steps } from 'src/types';
import { DetalleMotivo, PantallaNombreApellido } from 'src/components';
import { FormController, useOneFormContext } from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { ConfirmacionCEMSegundaEntrega, ThankYouCEMSegundaEntregaValoracion } from 'src/forms/Cem/CEMSegundaEntrega';
import { formDataBuilder } from 'src/forms/format-fields';
import { getSfAddressRest, sanitizeKvps } from 'src/forms/Cem/helpers';
import { addRemovedStep, getIndexInSteps, removeMultipleStepsAndResetScreenIndex, resetStepsScreenIndex } from 'src/forms/helpers';
import { formatToTwoChars, strTitlelize } from 'src/helpers';
import { RadioTextGroup } from 'src/components/inputs/RadioTextGroup';

export interface CEMSegundaEntregaValoracionFormProps {
	handleError: () => void,
	idPerson: IdPerson;
	idVehicle: IdVehicle;
	idDealer: IdDealer;
	idOrder: IdOrder;
}

export function CEMSegundaEntregaValoracionForm(props: CEMSegundaEntregaValoracionFormProps) {
	const {
		handleError,
		idPerson,
		idVehicle,
		idDealer,
		idOrder,
	} = props;
	const { initializeForm, handleNextStep } = useOneFormContext();
	const store = useStore();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

	const urlParams = new URLSearchParams(window.location.search);
	const agency = urlParams.get('agency');
	const adviser = urlParams.get('adviser');
	const pincode = urlParams.get('pincode');
	const rating = urlParams.get('rating');

	const { formData } = useSelector((state: OneFormState) => state);
	const { enviarConcesion } = formData.fields;

	const optionsEnviarConcesion = [
		{
			value: '3',
			label: 'Sí, mis respuestas pueden ser enviadas y analizadas junto a mi nombre y al número de bastidor de mi vehículo, para el alcance descrito anteriormente (el consentimiento puede revocarse en cualquier momento a través de los canales de contacto que se indican a continuación*)',
			info: '*Puedo revocar este consentimiento en cualquier momento sin dar motivos a mi Distribuidor para mis datos personalizados.',
		},
		{
			value: '2',
			label: 'Mis respuestas pueden ser enviadas pero sin mencionar mi nombre y al número de bastidor de mi vehículo',
			info: 'Seleccionando esta opción, el punto de venta no podrá ponerse en contacto contigo en relación a tus comentarios.',
		},
		{
			value: '0',
			label: 'Mis respuestas solo deben ser analizadas de manera anónima',
			info: 'Seleccionando esta opción, tus respuestas serán analizadas de modo agregado junto al resto de respuestas de todos los entrevistados y no se trasladarán a los comentarios abiertos.',
		},
	];

	let index = 0;
	const steps: Steps[] = [
		{
			title: (
				<>
					<Text bold>Recibido. </Text>
					<Text>¿Te gustaría compartirnos algo más?</Text>
				</>
			),
			fields: <OneElementTemplateFullScreen element={<DetalleMotivo label='Escríbelo aquí' name="detalles" numberOfMaxLength={300} />} />,
			screenIndex: index++,
			name: 'Detalles',
			outputs: ['detalles'],
			outputsText: ['detalles'],
		},
		{
			title: (
				<>
					Finalmente nos gustaría saber si podemos <Text bold>enviar tus respuestas junto con tu nombre al punto de venta {idDealer?.VGED_CommercialName__c ? strTitlelize(idDealer.VGED_CommercialName__c) : ''} y el bastidor del vehículo, y puedan usarlos para análisis adicionales.</Text>
				</>
			),
			fields: <OneElementTemplateFullScreen element={<RadioTextGroup name="enviarConcesion" options={optionsEnviarConcesion} />} />,
			screenIndex: index++,
			name: 'EnviarConcesion',
			outputs: ['enviarConcesion'],
			outputsText: ['enviarConcesion'],
			ctas: optionsEnviarConcesion,
		},
		{
			title: (
				<>
					Por favor, <Text bold>introduce o comprueba tus datos:</Text>
				</>
			),
			fields: (
				<OneElementTemplateFullScreen
					element={
						<div style={{ paddingBottom: '2px' }}>
							<PantallaNombreApellido />
						</div>
					}
				/>
			),
			screenIndex: index++,
			name: 'DatosPersonales',
			outputs: ['name', 'surname', 'secondSurname'],
			outputsText: ['name', 'surname', 'secondSurname'],
		},
		{
			fields: <ConfirmacionCEMSegundaEntrega idPerson={idPerson} />,
			screenIndex: index++,
			name: 'Confirmacion',
			outputs: ['confirmacion'],
			outputsText: ['confirmacion'],
		},
	];

	useEffect(() => {
		if (!enviarConcesion) {
			return;
		}
		if (enviarConcesion === '3') {
			addSteps(['DatosPersonales']);
		} else {
			removeSteps(['DatosPersonales']);
		}
	}, [enviarConcesion]);


	/**
	 * Se extrae la lógica para poder utilizarla por separado sin tener que usar las funciones de loading o updateSteps
	 * ya que hay una pregunta en la que podemos eliminar y añadir un step en el mismo paso.
	 * @param stepsToAdd
	 * @param alternativeSteps
	 * @returns
	 */
	const addingStepsLogic = (
		stepsToAdd: string[],
		alternativeSteps?: Steps[],
	) => {
		const { steps: actualSteps, screenIndex } = store.getState().formInfo;
		const stepsToUse = alternativeSteps ? alternativeSteps : actualSteps;
		// Hay que revertir los steps para que se añadan en el orden correcto
		const indexesInSteps = stepsToAdd
			.reverse()
			.map((step) => getIndexInSteps(stepsToUse, step));
		let newSteps = [...stepsToUse];

		indexesInSteps.forEach((index, i) => {
			if (index === -1) {
				newSteps = addRemovedStep(
					steps,
					stepsToUse,
					stepsToAdd[i],
					screenIndex,
				);
			}
		});
		const finalSteps = resetStepsScreenIndex(newSteps);
		return finalSteps;
	};

	const addSteps = (stepsToAdd: string[]) => {
		setLoading(true);

		const finalSteps = addingStepsLogic(stepsToAdd);
		updateSteps(finalSteps);
		handleNextStep();

		setLoading(false);
	};

	const removeSteps = (stepsToDelete: string[]) => {
		const { steps } = store.getState().formInfo;
		setLoading(true);
		const finalSteps = removeMultipleStepsAndResetScreenIndex(
			steps,
			stepsToDelete,
		);
		updateSteps(finalSteps);
		setLoading(false);
	};

	const updateSteps = (steps: Steps[]) => {
		dispatch({ type: 'UPDATE_STEPS', payload: steps });
		dispatch({ type: 'UPDATE_LAST_STEP', payload: steps[steps.length - 1] });
		dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: steps.length });
	};


	// Datos de envío
	const CEMSegundaEntregaValoracionData: Partial<FormDataGroup> = {
		headers: {},
		fields: {
			formName: 'cem-segunda_entrega-valoracion',
			formType: 'otherform',
			pageCategory: 'Encuesta',
		}, // Analítica
	};

	// Presubmit
	useEffect(() => {
		if (idDealer) sendPresubmit();
	}, [idDealer]);

	const sendPresubmit = async () => {
		const result = await sendForm(true);

		if (!result) {
			handleError();
		}
		setLoading(false);
	};

	const sendForm = (isPresubmit?: boolean): Promise<boolean> => {
		const { formData }: OneFormState = store.getState();
		const { fields } = formData;

		return new Promise<any>(async (resolve, reject) => {
			const { tc, today, url, device } = getParamsForm();

			const data = {
				'headers[FORM_NAME]': 'vw-oneform-cem-segunda_entrega-valoracion',
				'headers[FORM_VERSION]': 'vw-oneform-cem-segunda_entrega-valoracion-' + process.env.VERSION,
				'headers[FORM_URL]': url,
				'headers[FORM_DATE_INSERTION]': today,
				'headers[FORM_DETAILS]': 'CEM Segunda entrega valoración',
				'headers[FORM_OBJECTIVE]': 'Encuesta',
				'headers[ORIGIN_DEVICE_TYPE]': device,
				'headers[ORIGIN_TRACKING_CODE]': tc,
				'headers[DPS_TOKEN]': 'VW_DDB_FRM_CEM_SEGUNDA_ENTREGA_VALORACION',
				'headers[FK_SYSTEM]': idOrder.VGED_ClimaproOrderID__c,

				'fields[DPS_BRAND]': 'Volkswagen',
				'fields[SF_LEAD_INSTALLATION_ID]': idDealer?.Id,
				'fields[SF_LEAD_INSTALLATION_NAME]': idDealer?.VGED_CommercialName__c,
				'fields[SF_LEAD_INSTALLATION_CODE]': idDealer?.VGED_DealerCode__c,
				'fields[SF_LEAD_INSTALLATION_CODE_KVPS]': idDealer?.KVPSCode__c ? sanitizeKvps(idDealer?.KVPSCode__c) : '',
				'fields[VEH_ACTUAL_FRAME]': idVehicle?.Name,
				'fields[VEH_ACTUAL_PLATE]': idVehicle?.VGED_RegistrationPlate__c,
				'fields[VEH_ACTUAL_MODEL]': idVehicle?.VGED_ModelGroup__c,
				'fields[PERSON_NAME]': fields.name ? fields.name : idPerson?.FirstName,
				'fields[PERSON_SURNAME_1]': fields.name ? fields.name : idPerson?.LastName,
				'fields[PERSON_SURNAME_2]': fields.secondSurname ? fields.secondSurname : idPerson?.MiddleName,
				'fields[PERSON_PHONE_MOBILE]': idPerson?.PersonMobilePhone,
				'fields[PERSON_EMAIL]': idPerson?.PersonEmail,
				'fields[PERSON_POSTAL_ROAD_NAME]': getSfAddressRest(idPerson?.PersonMailingAddress, 'street'),
				'fields[PERSON_POSTAL_CP]': getSfAddressRest(idPerson?.PersonMailingAddress, 'postalCode'),
				'fields[PERSON_POSTAL_PROVINCE_CODE]': formatToTwoChars(getSfAddressRest(idPerson?.PersonMailingAddress, 'stateCode')),
				'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
				'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
				'fields[SURVEY_ANSWER_01]': rating,
				'fields[SURVEY_ANSWER_05]': adviser,

				'flags[PERSON_RGPD_ADAPTED]': 'true',
				'flags[FLAG_RELACION_MARCA_SIN_IC]': 'false',
				'flags[FLAG_AGENCY]': agency == 'AGENCIA' ? 'true' : 'false',
			};

			if (idOrder.RCY_ActualDeliveryDate__c && idOrder.RCY_ActualDeliveryDate__c != 'null') {
				data['fields[SURVEY_ANSWER_02]'] = idOrder.RCY_ActualDeliveryDate__c;
			}

			if (!isPresubmit) {
				data['headers[PINCODE]'] = pincode;
				data['headers[DPS_TOKEN]'] = 'VW_DDB_FRM_CEM_SEGUNDA_ENTREGA_VALORACION_CONTESTADO';
				data['fields[COMMENTS_PERSON]'] = fields.detalles;
				data['fields[SURVEY_ANSWER_34]']= fields.enviarConcesion;
			}
			const dataStringified = JSON.stringify(data);
			const dataParsed = JSON.parse(dataStringified);
			const formData = formDataBuilder(dataParsed);
			const response = await oneformBFFNdpsSend(formData);

			if (response && response.data?.status?.code == 200) {
				dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
				resolve(true);
			} else {
				resolve(false);
			}
		});
	};


	// Inicialización del form
	const getFormInfo = (): {
		firstStep: Steps;
		lastStep: Steps;
		stepsHistory: number[];
		numberOfScreens: number;
		newSteps?: Steps[];
	} => {
		return {
			firstStep: steps[0],
			lastStep: steps[steps.length - 1],
			stepsHistory: [0],
			numberOfScreens: steps.length,
			newSteps: null,
		};
	};

	useEffect(() => {
		const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

		const CEMSegundaEntregaValoracionInfo: Partial<FormInfo> = {
			formStarted: true,
			sendFormCallback: sendForm,
			fullScreen: firstStep.fullScreen ? true : false,
			lastStep,
			numberOfScreens,
			screenIndex: firstStep.screenIndex,
			formTheme: 'main',
			notALayer: true,
			formType: 'inPage',
			step: firstStep,
			steps: newSteps ? newSteps : steps,
			stepsHistory,
			thankYouComponent: <ThankYouCEMSegundaEntregaValoracion />,
		};
		initializeForm(CEMSegundaEntregaValoracionInfo, CEMSegundaEntregaValoracionData);
	}, [idDealer]);

	return steps && !loading ? <FormController steps={steps} screenType="full-screen" /> : null;
}
