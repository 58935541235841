import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { LoadingEncuestas } from 'src/components';
import { useFeatureServices, useFaContent } from 'src/feature-app';
import { EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { IdDealer, IdOrder, IdPerson, IdVehicle } from 'src/types';
import { GenericErrorLayer } from 'src/components/layers';
import { createPincodeEntity } from 'src/forms/dps-utils';
import { getInstallation } from 'src/forms/Cem/helpers';
import { CEMVentaProductoForm } from 'src/forms/Cem/CEMVentaProducto/CEMVentaProductoForm';

export function CEMVentaProductoController() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [usado, setUsado] = useState(false);
	const [caducado, setCaducado] = useState(false);
	const today = dayjs(new Date());

	const dpsDataRef = useRef(null);
	const idPersonRef = useRef(null);
	const idVehicleRef = useRef(null);
	const idDealerRef = useRef(null);
	const idOrderRef = useRef(null);

	const [installation, setInstallation] = useState(null);

	const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;
	const dispatch = useDispatch();
	const faContent = useFaContent();

	const handleNext = (value: any) => { };
	const handleComplete = async () => {
		const dpsData = oneFormService.getDpsData();

		if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
			handleError('Pincode not found');
		} else if (dpsData.pincode.pincodeUsed) {
			setUsado(true);
			setLoading(false);
		} else {
			const idPerson = createPincodeEntity(dpsData, 'PERSON') as IdPerson;
			const idVehicle = createPincodeEntity(dpsData, 'VEHICLE') as IdVehicle;
			const idDealer = createPincodeEntity(dpsData, 'DEALER') as IdDealer;
			const idOrder = createPincodeEntity(dpsData, 'ORDER') as IdOrder;

			dpsDataRef.current = dpsData;
			idPersonRef.current = idPerson;
			idVehicleRef.current = idVehicle;
			idDealerRef.current = idDealer;
			idOrderRef.current = idOrder;

			const fechaEnvio = dpsData.fe ? dayjs(atob(dpsData.fe), 'DD/MM/YYYY') : null;
			if (!fechaEnvio || today.diff(dayjs(fechaEnvio), 'day') > 0) {
				setCaducado(true);
			} else {
				const installation = await getInstallation(dpsData, idDealer);
				if (installation) {
					setInstallation(installation);
				}
				else {
					handleError();
				}
			}
			setLoading(false);
		}
	};

	const handleError = (message?: string) => {
		setError(true);
		setLoading(false);
		if (message) {
			console.error(message);
		}
	};

	useEffect(() => {
		const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

		oneFormCoreDpsIsLoaded.subscribe({
			next(value) {
				handleNext(value);
			},
			error(err) {
				console.error(err);
				handleError();
			},
			complete() {
				handleComplete();
			},
		});
	}, []);

	if (loading) {
		return <LoadingEncuestas />;
	} else if (error) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, algo ha pasado"
					bodycopy="Por alguna razón no hemos podido cargar este cuestionario."
					ctaLayer="Reintentar"
					notShowCTA={true}
					icon={false}
					notALayer={true}
				/>
			</>
		);
	} else if (caducado) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, este formulario ha caducado"
					bodycopy="Parece que el cuestionario ya no está disponible. ¿Quieres contarnos cómo ha sido tu experiencia?"
					ctaLayer="Contacta con nosotros"
					ctaType="a"
					urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
					icon={false}
					notALayer={true}
				/>
			</>
		);
	} else if (usado) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, ya has respondido a este cuestionario"
					bodycopy="Parece que ya has compartido tu opinión con nosotros. ¿Quieres añadir algo más?"
					ctaLayer="Contacta con nosotros"
					ctaType="a"
					urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
					icon={false}
					notALayer={true}
				/>
			</>
		);
	} else {
		return (
			<CEMVentaProductoForm
				installation={installation}
				dpsData={dpsDataRef.current}
				idDealer={idDealerRef.current}
				idOrder={idOrderRef.current}
				idPerson={idPersonRef.current}
				idVehicle={idVehicleRef.current}
			/>
		);
	}
}
