import React, { useEffect, useState } from 'react';
import { useFaContent } from 'src/feature-app';
import { AutosubmitController } from 'src/forms/Autosubmit/AutosubmitController';

export function AutosubmitTrigger() {
	const faContent = useFaContent();
	const [action, setAction] = useState(null);

	useEffect(() => {
		setAction(faContent.action);
	}, [faContent]);

	const renderForm = () => {
		if (action) {
			return <AutosubmitController action={action} />;
		} else {
			return null;
		}
	};

	return renderForm();
}
