import React, { useState } from 'react';
import { RadioButton, Richtext, styled, Text, TokenTextAppearance, TokenTextColor } from '@volkswagen-onehub/components-core';
import { Info } from 'src/icons-core-imports';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { Option, OneFormState } from 'src/types';

const LabelInfoWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const InfoWrapper = styled.div`
	margin-left: 16px;
	cursor: pointer;
	svg {
		fill: #001e50;
	}
`;

interface props {
	name: string;
	options: Array<Option>;
}

export const RadioTextGroup = (props: props) => {
	const { name, options } = props;

	const { handleNextStep } = useOneFormContext();
	const dispatch = useDispatch();
	const { formData } = useSelector((state: OneFormState) => state);
	const { fields } = formData;
	const [value, setValue] = useState(fields[name]);
	const [showInfo, setShowInfo] = useState<number | undefined>();
	const { register } = useFormContext();

	const handleCheck = (value: string) => {
		setValue(value);
		dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: value } });
		setTimeout(() => {
			handleNextStep();
		}, 0);
	};

	return (
		<div style={{ paddingTop: '42px' }}>
			{options.map((option, index) => (
				<div key={index} style={{ paddingBottom: '20px', paddingLeft: '12px' }}>
					<LabelInfoWrapper className="label-info-wrapper">
						<RadioButton
							value={option.value}
							label={option.label}
							name={name}
							onClick={(e) => {
								e.preventDefault();
								handleCheck(option.value);
							}}
							checked={value ==option.value}
						/>
						{option?.info && (
						<InfoWrapper
							onClick={(e) => {
								e.preventDefault();
								setShowInfo(index);
							}}
						>
							<Info />
						</InfoWrapper>
						)}
					</LabelInfoWrapper>
					{option?.info && showInfo == index && (
						<div style={{ paddingTop: '8px', paddingLeft: '36px' }}>
							<Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
								<Richtext dangerouslyParseRawHTML markdown={option.info}/>
							</Text>
						</div>
					)}
				</div>
			))}
			
			<input
				type="hidden"
				name={name}
				value={value}
				ref={register({
					required: {
						value: true,
						message: 'Requerido',
					},
				})}
			/>
		</div>
	);
};
