import dayjs from 'dayjs';
import { strTitlelize } from 'src/helpers';
import {
  CTAsMotivo,
  CTAsDudas,
  contactoOptionsSAC,
  CTAsNuevoOcasión,
  CTAsUsadoNuevo,
  CTAsFormasMovilidad,
  CTAsRazones, 
  contactoOptionsTurismos,
  CTAsLugarCompra
,} from 'src/feature-app/InputsValues/CTAsObjects';
import { 
  CTAsDesplazamientoTaigo,
  CTAsMotivacionCompraTaigo
} from 'src/feature-app/InputsValues/CTAsObjectsFormsAdhoc';
import { 
  franjaHoraria,
  meses 
} from 'src/feature-app/InputsValues/OtherInputTypesObjects';
import { 
  isGooglePlace, 
  weekDay_DayNumber_Month, 
  weekDay_DayNumber_Month_Hour 
} from 'src/feature-app';
import { Installation, isDealerData } from 'src/types';

//FUNCIONES GESTIÓN OUTPUTS

// cambio value por label
export const changeValueForLabel = (ctas: any, fields: any, field: string) => {
  const result = ctas.find((option: any) => option.value === fields[field]);
  return result ? result.label : fields[field];
};

// cambio value por label y concatenación multipleChoice
const formatMultipleOptionText = (ctas: any, fields: any, field: string) => {
  const aux = fields[field];
  const prevStepContent = ctas.filter((item: any) => aux.includes(item.value));
  const result = prevStepContent.map((item: any) => item.label);
  return result.join(', ');
};

// formacion frase slot de cita
const getOutputSlotFrom = (fields: any) => {

  const selectedMotivo = CTAsMotivo.find((CTA) => CTA.value === fields.motivo);

  if (fields.dealer.dmsInfo && fields.dealer.dmsInfo.dmsAvailable) {
    const momentSlot = weekDay_DayNumber_Month_Hour(fields.slot.from);
    const formatedSlot =
      momentSlot.split(',')[0] +
      ' de ' +
      momentSlot.split(',')[1].charAt(0).toLowerCase() +
      momentSlot.split(',')[1].slice(1);
    return selectedMotivo
      ? `${selectedMotivo.detallesPrevStep} el ${formatedSlot.replace('-', ' a las ').concat('h')}`
      : `El ${formatedSlot.replace(',', ' de ').concat('h')}`;
  } else {
    const momentSlot = weekDay_DayNumber_Month(fields.slot.from);
    const formatedSlot =
      momentSlot.split(',')[0] +
      ' de ' +
      momentSlot.split(',')[1].charAt(0).toLowerCase() +
      momentSlot.split(',')[1].slice(1);

    const horarioTranslate = franjaHoraria.find((h: any) => h.code === fields.slot.dayMoment.code);
    return selectedMotivo
      ? `${selectedMotivo.detallesPrevStep} el ${formatedSlot.concat(horarioTranslate.name)}`
      : `El ${formatedSlot.concat(horarioTranslate.name)}`;
  }
};

// output localización
const getLocationOutput = (formInfo: any) => {

  if (isGooglePlace(formInfo.userSuggestion)) {
    return formInfo.userSuggestion.name;
  } else if (isGooglePlace(formInfo.userSuggestion) && isDealerData(formInfo.userSuggestion)) {
    return `${formInfo.userSuggestion.name} en ${formInfo.userSuggestion.markerInfo.dealerInfo.address}`;
  } else if (isGooglePlace(formInfo.userSuggestion) && !isDealerData(formInfo.userSuggestion)) {
    return `${(formInfo.userSuggestion as Installation).TXT_V_Installation_Data_Name__c} en ${
      (formInfo.userSuggestion as Installation).TXT_V_Address__c
    }`;
  }
};

const getUnknowOutput = (fields: any[], field: string) =>  {
  if (fields[field] == 'true') {
    return 'Sí';
  }
  if (fields[field] == 'false') {
    return 'No';
  }
  if (dayjs(fields[field], 'YYYY-MM-DD').isValid()) {
    return dayjs(fields[field], 'YYYY-MM-DD').format('DD/MM/YYYY');
  }
  if (typeof fields[field] == 'string') {
    return fields[field].replace(/\,+/g, ', '); 
  }

  return fields[field];
};

// SWITCH CASE PARA DETERMINAR EL OUTPUT DEL PREVIOUS STEP

export const getUpdatedField = (field: string, formData: any, dealersInfo: any, formInfo: any, previousStep?: any) =>  {

  const { fields } =  formData;
  const { formName } = fields;
  const { selectedInstallation } = dealersInfo;

  if (previousStep?.ctas) {
    return changeValueForLabel(previousStep.ctas, fields, field);
  }
  
  switch(field) {
    case 'motivo':
      return changeValueForLabel(CTAsMotivo, fields, field);
    case 'contacto':
      return changeValueForLabel(contactoOptionsTurismos, fields, field);
    case 'contactoSAC':
      return changeValueForLabel(contactoOptionsSAC, fields, field);
    case 'tipoCoche':
      return changeValueForLabel(CTAsNuevoOcasión, fields, field);
    case 'usadoNuevo':
      return changeValueForLabel(CTAsUsadoNuevo, fields, field);
    case 'desplazamiento':
      return changeValueForLabel(CTAsDesplazamientoTaigo, fields, field);
    case 'motivacionCompra' : 
      return changeValueForLabel(CTAsMotivacionCompraTaigo, fields, field);
    case 'lugarCompra':
      return changeValueForLabel(CTAsLugarCompra, fields, field);
    case 'slot.from':
      return getOutputSlotFrom(fields);
    case 'dealer.name':
      return fields.dealer.name;
    case 'installation':
      return strTitlelize(selectedInstallation.TXT_V_Installation_Data_Name__c != 'undefined' ? selectedInstallation.TXT_V_Installation_Data_Name__c : selectedInstallation.VGED_CommercialName__c);
    case 'installation-complete':
      return typeof selectedInstallation.TXT_V_Installation_Data_Name__c != 'undefined' ? 
      strTitlelize(selectedInstallation.TXT_V_Installation_Data_Name__c + ', ' + selectedInstallation.TXT_V_Address__c + ' ' + selectedInstallation.TXT_V_Postal_Code__c + ' ' + selectedInstallation.TXT_V_Location__c) 
      : 
      strTitlelize(selectedInstallation.VGED_CommercialName__c + ', ' + selectedInstallation.ShippingStreet + ' ' + selectedInstallation.ShippingPostalCode + ' ' + selectedInstallation.ShippingCity)
    case 'location':
      return getLocationOutput(formInfo);
    case 'kilometros':
      if (fields.kilometros === 0) {
        return 'No estoy seguro';
      } else {
        return `${fields.kilometros[0]}km - ${fields.kilometros[1]}km`;
      }
    case 'cellphone':
      return `${fields.prefix} ${fields[field]}`;
    case 'informado':
      let result;
      if (fields[field] === 'false') {
        result = 'No';
      } else if (fields[field] === 'true') {
        result = 'Sí';
      } else {
        result = 'Sin respuesta';
      }
      return result ? result : fields[field];
    case 'otraMarca':
      return fields[field] === 'false' ? 'No' : fields[field];
    case 'publicarRespuestas':
      return fields[field] === '1' ? 'Sí' : 'No';
    case 'brand':
      if(fields[field] === 'false') {
        return 'No';
      } else {
        return fields[field];
      }
    case 'model':
      if(fields[field] === 'false') {
        return '';
      } else {
        return fields[field];
      }
    case 'dudas':
      return formatMultipleOptionText(CTAsDudas, fields, field);
    case 'formasMovilidad':
      return formatMultipleOptionText(CTAsFormasMovilidad, fields, field); 
    case 'razones':
      return formatMultipleOptionText(CTAsRazones, fields, field); 
    default:
      return getUnknowOutput(fields, field);
  }
  
};