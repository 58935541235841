import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import { FormController, isHighRating, isLowRating, isNoRating, useOneFormContext } from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { CemFormProps, getStepsVenta, LastQuestionFlow } from 'src/forms/Cem';
import { checkUndefinedsInData, formatSinRespuestaValue, getCemDate, getSfAddressRest, sanitizeKvps, stepNameToFieldName, valueWrongConcesion, valueWrongMarca, valueWrongModelo, valueWrongPersona } from 'src/forms/Cem/helpers';
import { ThankYouCEM } from 'src/forms/Cem/ThankYouCem';
import { formatHeaders, formDataBuilder } from 'src/forms/format-fields';
import { addRemovedStep, getArrayOfStepsNames, getIndexInSteps, removeMultipleStepsAndResetScreenIndex, resetStepsScreenIndex, } from 'src/forms/helpers/add-remove-steps';
import { formatToTwoChars, strTitlelize } from 'src/helpers';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';

export function CemFormVenta(props: CemFormProps) {
	const { idOrder, idPerson, idVehicle, idDealer, dpsData, installation } = props;
	const { initializeForm, handleNextStep } = useOneFormContext();
	const { formData } = useSelector((state: OneFormState) => state);
	const store = useStore();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const urlParams = new URLSearchParams(window.location.search);
	const adviser = urlParams.get('adviser');
	const agency = urlParams.get('agency');
	const dev = urlParams.get('dev') !== null;

	const {
		datosCorrectos,
		datosIncorrectos,
		confirmacionConcesion,
		satisfaccionTrato,
		satisfaccionOrganizacion,
		satisfaccionAsesor,
		satisfaccionConcesion,
		satisfaccionTestDrive,
		testDrive,
		modelo,
		enviarConcesion,
		masPreguntas,
	} = formData.fields;

	const isElectrico = idVehicle.VGED_ModelGroup__c?.substring(0, 2) == 'ID';

	const modeloRef = useRef(null); // Necesitamos almacenar el modelo ya que cada vez que el usuario selecciona un modelo se modifican los steps, por lo que necesitamos una referencia para saber que modelo hay que sustituir.

	const getInstallationName = () => {
		return strTitlelize(
			installation?.VGED_CommercialName__c  
				? installation.VGED_CommercialName__c 
				: idDealer?.VGED_CommercialName__c 
				? idDealer.VGED_CommercialName__c 
				: ''
		);
	};

	const NuevoFormData: Partial<FormDataGroup> = {
		headers: {
			FORM_DETAILS: 'CEM Venta',
			FORM_OBJECTIVE: 'Encuesta',
			DPS_TOKEN: 'VW_DDB_FRM_CEM_VN_2025',
			FORM_ID: 'Email_B04_C01_CEM_Sales',
		},
		fields: {
			lssiPincode: idPerson.LSSI__c,
			formName: 'cem-venta',
			formType: 'otherform',
			pageCategory: 'Encuesta',
			name: idPerson.FirstName,
			surname: idPerson.LastName,
			secondSurname: idPerson.MiddleName,
			installation: getInstallationName(),
		}, // Analítica
	};

	const sendSurvey = () => {
		const { formData }: OneFormState = store.getState();
		const { fields } = formData;

		return !( // negation
			(fields.datosCorrectos == 'false' && fields.datosIncorrectos == valueWrongMarca) ||
			(fields.datosCorrectos == 'false' && fields.datosIncorrectos == valueWrongPersona) ||
			(fields.datosCorrectos == 'false' && fields.datosIncorrectos == valueWrongConcesion && fields.confirmacionConcesion == 'false')
		);
	};

	const getData = (addSurvey?: boolean) => {
		const { formData }: OneFormState = store.getState();
		const { fields, headers } = formData;
		const { tc, today, url, device } = getParamsForm();

		const data = {
			...formatHeaders(headers),
			'headers[FORM_NAME]': `vw-oneform-${fields.formName}`,
			'headers[FORM_VERSION]': `vw-oneform-${fields.formName}-${process.env.VERSION}`,
			'headers[FORM_URL]': url,
			'headers[FORM_DATE_INSERTION]': today,
			'headers[ORIGIN_DEVICE_TYPE]': device,
			'headers[ORIGIN_TRACKING_CODE]': tc,
			'headers[PINCODE]': dpsData.pincode.pincodeResponse.params.pincode,
			'headers[FK_SYSTEM]': idOrder.VGED_ClimaproOrderID__c,

			'fields[DPS_BRAND]': 'Volkswagen',
			'fields[SF_LEAD_INSTALLATION_ID]': installation.Id,
			'fields[SF_LEAD_INSTALLATION_NAME]': installation.VGED_CommercialName__c,
			'fields[SF_LEAD_INSTALLATION_CODE]': installation.VGED_ConcessionDealerCode__c,
			'fields[SF_LEAD_INSTALLATION_CODE_KVPS]': sanitizeKvps(installation.KVPSCode__c),
			'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
			'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
			'fields[PERSON_NAME]': fields.name,
			'fields[PERSON_SURNAME_1]': fields.surname,
			'fields[PERSON_SURNAME_2]': fields.secondSurname,
			'fields[PERSON_EMAIL]': idPerson.PersonEmail,
			'fields[PERSON_PHONE_MOBILE]': idPerson.PersonMobilePhone,
			'fields[PERSON_POSTAL_ROAD_NAME]': getSfAddressRest(idPerson.PersonMailingAddress, 'street'),
			'fields[PERSON_POSTAL_CP]': getSfAddressRest(idPerson?.PersonMailingAddress, 'postalCode'),
			'fields[PERSON_POSTAL_LOCATION]': getSfAddressRest(idPerson?.PersonMailingAddress, 'city'),
			'fields[PERSON_POSTAL_PROVINCE_CODE]': formatToTwoChars(getSfAddressRest(idPerson?.PersonMailingAddress, 'stateCode')),
			'fields[VEH_ACTUAL_PLATE]': idVehicle.VGED_RegistrationPlate__c,
			'fields[VEH_ACTUAL_FRAME]': idVehicle.Name,
			'fields[VEH_ACTUAL_MODEL]': idVehicle.VGED_ModelGroup__c ? idVehicle.VGED_ModelGroup__c : dpsData.model ? dpsData.model : null,

			'fields[SURVEY_ANSWER_01]': fields.datosCorrectos == 'true' ? '1' : '2',
			'fields[SURVEY_ANSWER_02]': fields.datosCorrectos == 'false' ? fields.datosIncorrectos : null,
			'fields[SURVEY_ANSWER_03]': fields.datosCorrectos == 'false' && fields.datosIncorrectos == 2 ? fields.modelo : null,
			'fields[SURVEY_ANSWER_04]': fields.datosCorrectos == 'false' && fields.datosIncorrectos == 3 ? fields.confirmacionConcesion == 'true' ? '1' : '2' : null,
			// conditional send
			'fields[SURVEY_ANSWER_30]': fields.publicarRespuestas,
			'fields[SURVEY_ANSWER_32]': adviser,
			'fields[SURVEY_ANSWER_34]': fields.enviarConcesion,

			'flags[PERSON_RGPD_ADAPTED]': 'true',
			//'flags[FLAG_RELACION_MARCA_SIN_IC]': 'false',
		};

		if(sendSurvey() || addSurvey) {
			data['fields[SURVEY_ANSWER_06]'] = formatSinRespuestaValue(fields.satisfaccionTrato);
			data['fields[SURVEY_ANSWER_07]'] = formatSinRespuestaValue(fields.satisfaccionTratoMotivo);
			data['fields[SURVEY_ANSWER_08]'] = formatSinRespuestaValue(fields.satisfaccionOrganizacion);
			data['fields[SURVEY_ANSWER_09]'] = formatSinRespuestaValue(fields.satisfaccionOrganizacionMotivo);
			data['fields[SURVEY_ANSWER_10]'] = formatSinRespuestaValue(fields.satisfaccionAsesor);
			data['fields[SURVEY_ANSWER_11]'] = formatSinRespuestaValue(fields.satisfaccionAsesorMotivo);
			data['fields[SURVEY_ANSWER_12]'] = formatSinRespuestaValue(fields.satisfaccionConcesion);
			data['fields[SURVEY_ANSWER_13]'] = formatSinRespuestaValue(fields.satisfaccionConcesionMotivo);
			data['fields[SURVEY_ANSWER_14]'] = formatSinRespuestaValue(fields.aspectoSatisfaccionMotivo);
			data['fields[SURVEY_ANSWER_15]'] = formatSinRespuestaValue(fields.testDrive);
			data['fields[SURVEY_ANSWER_16]'] = formatSinRespuestaValue(fields.contactoSatisfaccion);
			data['fields[SURVEY_ANSWER_17]'] = formatSinRespuestaValue(fields.informacionUsoVehiculo);
			data['fields[SURVEY_ANSWER_18]'] = formatSinRespuestaValue(fields.satisfaccionTestDrive);
			data['fields[SURVEY_ANSWER_19]'] = formatSinRespuestaValue(fields.satisfaccionTestDriveMotivo);
		}

		if (agency) {
			data['flags[FLAG_AGENCY]'] = agency == 'AGENCIA' ? 'true' : 'false';
		}

		if (idOrder.VGED_CEMDeliveryDate__c && idOrder.VGED_CEMDeliveryDate__c != 'null') {
			data['fields[CEM_DATE]'] = getCemDate(idOrder.VGED_CEMDeliveryDate__c);
		}

		return data;
	};

	useEffect(() => {
		if(dev) {
			console.log(getData(true));
		}
	}, [formData]);

	const sendForm = (): Promise<boolean> => {

		return new Promise<any>(async (resolve, reject) => {

			const dataStringified = JSON.stringify(getData());
			const dataParsed = JSON.parse(dataStringified);
			const formData = formDataBuilder(dataParsed);
			checkUndefinedsInData(getData(), 'Venta');
			const response = await oneformBFFNdpsSend(formData);

			if (response && response.data?.status?.code == 200) {
				dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
				resolve(true);
			} else {
				resolve(false);
			}
		});
	};

	/**
	 * Se extrae la lógica para poder utilizarla por separado sin tener que usar las funciones de loading o updateSteps ya que hay una pregunta en la que podemos eliminar y añadir un step en el mismo paso.
	 *
	 * @param stepsToAdd
	 * @param alternativeSteps
	 * @returns
	 */
	const addingStepsLogic = (stepsToAdd: string[], alternativeSteps?: Steps[]) => {
		const { steps: actualSteps, screenIndex } = store.getState().formInfo;
		const stepsToUse = alternativeSteps ? alternativeSteps : actualSteps;
		// Hay que revertir los steps para que se añadan en el orden correcto
		const indexesInSteps = stepsToAdd.reverse().map((step) => getIndexInSteps(stepsToUse, step));
		let newSteps = [...stepsToUse];

		indexesInSteps.forEach((index, i) => {
			if (index === -1) {
				newSteps = addRemovedStep(getStepsVenta(getInstallationName(), getVehicleName()), stepsToUse, stepsToAdd[i], screenIndex);
			}
		});
		const finalSteps = resetStepsScreenIndex(newSteps);
		return finalSteps;
	};

	const addSteps = (stepsToAdd: string[]) => {
		setLoading(true);

		const finalSteps = addingStepsLogic(stepsToAdd);
		updateSteps(finalSteps);
		handleNextStep();

		setLoading(false);
	};

	const removeSteps = (stepsToDelete: string[]) => {
		const { steps } = store.getState().formInfo;
		setLoading(true);
		const finalSteps = removeMultipleStepsAndResetScreenIndex(steps, stepsToDelete);
		updateSteps(finalSteps);
		setLoading(false);
	};

	const updateSteps = (steps: Steps[]) => {
		dispatch({ type: 'UPDATE_STEPS', payload: steps });
		dispatch({ type: 'UPDATE_LAST_STEP', payload: steps[steps.length - 1] });
		dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: steps.length });
	};

	const getVehicleName = () => {
		if(!idVehicle) return '';
		return idVehicle.VGED_ModelGroup__c 
		? idVehicle.VGED_ModelGroup__c 
		: dpsData.model 
		? dpsData.model 
		: 'Volkswagen';
	};

	const replaceModelo = (string: string) => {
		if (modeloRef.current) {
			return string.replace(modeloRef.current, modelo);
		} else {
			return string.replace(idVehicle.VGED_ModelGroup__c, modelo);
		}
	};

	const modifyTitleSteps = (steps: Steps[], replaceCallback: (s: string) => string) => {
		return steps.map((step) => {
			if (!step.title) return step;
			if (Array.isArray(step.title.props.children)) {
				step.title.props.children = step.title.props.children.map((child: any) => {
					if (typeof child === 'object') {
						if (typeof child.props.children === 'object') {
							child.props.children.props.children = replaceCallback(child.props.children.props.children);
						} else {
							child.props.children = replaceCallback(child.props.children);
						}
					} else {
						child = replaceCallback(child);
					}
					return child;
				});
			} else if (typeof step.title.props.children === 'object') {
				step.title.props.children.props.children = replaceCallback(step.title.props.children.props.children);
			} else {
				step.title.props.children = replaceCallback(step.title.props.children);
			}
			return step;
		});
	};

	const getOrderStep = (order: number, sc4: number, sc5: number, sc6: number) => {
		return order === 1 ? sc4 : order === 2 ? sc5 : order === 3 ? sc6 : null;
	};

	const shuffle = (array: number[]): number[] => array.sort(() => Math.random() - 0.5);

	/**
	 * El orden de las preguntas Sc_4,Sc_5 y Sc_6 tiene que ser aleatorio.
	 * @param steps
	 */
	const randomizeSteps = (steps: Steps[]): Steps[] => {
		const sc4 = getIndexInSteps(steps, 'SatisfaccionTrato');
		const sc4Motivo = getIndexInSteps(steps, 'SatisfaccionTratoMotivo');

		const sc5 = getIndexInSteps(steps, 'SatisfaccionOrganizacion');
		const sc5Motivo = getIndexInSteps(steps, 'SatisfaccionOrganizacionMotivo');

		const sc6 = getIndexInSteps(steps, 'SatisfaccionAsesor');
		const sc6Motivo = getIndexInSteps(steps, 'SatisfaccionAsesorMotivo');

		let newSteps = [...steps];
		const [first, second, third] = shuffle([1, 2, 3]);
		const firstStep = getOrderStep(first, sc4, sc5, sc6);
		const secondStep = getOrderStep(second, sc4, sc5, sc6);
		const thirdStep = getOrderStep(third, sc4, sc5, sc6);
		newSteps[sc4] = steps[firstStep];
		newSteps[sc4Motivo] = steps[firstStep + 1];
		newSteps[sc5] = steps[secondStep];
		newSteps[sc5Motivo] = steps[secondStep + 1];
		newSteps[sc6] = steps[thirdStep];
		newSteps[sc6Motivo] = steps[thirdStep + 1];

		return newSteps;
	};

	const getFormInfo = (steps: Steps[]) => {
		const randomizedSteps = randomizeSteps(steps);

		return {
			firstStep: randomizedSteps[0],
			lastStep: randomizedSteps[randomizedSteps.length - 1],
			stepsHistory: [0],
			numberOfScreens: randomizedSteps.length,
			newSteps: randomizedSteps,
		};
	};

	// Lógica para modificar el flujo del formulario
	useEffect(() => {
		if (modelo) {
			const pastSteps = store.getState().formInfo.steps;
			const newSteps = modifyTitleSteps(pastSteps, replaceModelo);
			modeloRef.current = modelo;
			updateSteps(newSteps);
		}
	}, [modelo]);

	useEffect(() => {
		if (datosCorrectos === 'true') {
			const steps = store.getState().formInfo.steps;
			const modelo = getIndexInSteps(steps, 'Modelo');
			// En el caso de elegir que los datos son incorrectos, llegar a confirmación y volver atrás hay que regenerar todos los pasos.
			if (modelo === -1) {
				const initialSteps = store.getState().formInfo.initialSteps;
				const finalSteps = removeMultipleStepsAndResetScreenIndex(initialSteps, [
					'DatosIncorrectos',
					'Modelo',
					'ConfirmacionConcesion',
				]);
				updateSteps(finalSteps);
			} else {
				removeSteps(['DatosIncorrectos', 'Modelo', 'ConfirmacionConcesion']);
			}
		} else if (datosCorrectos === 'false') {
			addSteps(['DatosIncorrectos', 'Modelo']);
		}
	}, [datosCorrectos]);

	useEffect(() => {
		if (
			datosIncorrectos == valueWrongMarca ||
			datosIncorrectos == valueWrongPersona
		) {
			const pastSteps = store.getState().formInfo.steps;
			const newsteps = [pastSteps[0], pastSteps[1], pastSteps[pastSteps.length - 1]];
			const finalSteps = resetStepsScreenIndex(newsteps);
			updateSteps(finalSteps);
		}
		if (datosIncorrectos == valueWrongConcesion) {
			const initialSteps = store.getState().formInfo.initialSteps;
			const finalSteps = removeMultipleStepsAndResetScreenIndex(initialSteps, ['Modelo']);
			updateSteps(finalSteps);
		}
		if (datosIncorrectos == valueWrongModelo) {
			const initialSteps = store.getState().formInfo.initialSteps;
			const finalSteps = removeMultipleStepsAndResetScreenIndex(initialSteps, ['ConfirmacionConcesion']);
			updateSteps(finalSteps);
		}
	}, [datosIncorrectos]);

	useEffect(() => {
		if (confirmacionConcesion === 'false') {
			const pastSteps = store.getState().formInfo.steps;
			const newsteps = [pastSteps[0], pastSteps[1], pastSteps[2], pastSteps[pastSteps.length - 1]];
			const finalSteps = resetStepsScreenIndex(newsteps);
			updateSteps(finalSteps);
		} else if (confirmacionConcesion === 'true') {
			const initialSteps = store.getState().formInfo.initialSteps;
			const finalSteps = removeMultipleStepsAndResetScreenIndex(initialSteps, ['Modelo']);
			updateSteps(finalSteps);
		}
	}, [confirmacionConcesion]);

	useEffect(() => {
		if (!enviarConcesion) {
			return;
		}
		if (enviarConcesion === '3') {
			addSteps(['DatosPersonales']);
		} else {
			removeSteps(['DatosPersonales']);
		}
	}, [enviarConcesion]);

	useEffect(() => {
		if (!masPreguntas) {
			return;
		}

		if (masPreguntas === 'true') {
			const stepNames = getArrayOfStepsNames(getStepsVenta(getInstallationName(), getVehicleName()), 'MasPreguntas', 'EnviarConcesion');
			addSteps(stepNames);
		} else if (masPreguntas === 'false') {
			const { steps } = store.getState().formInfo;
			const stepNames = getArrayOfStepsNames(steps, 'MasPreguntas', 'EnviarConcesion');
			removeSteps(stepNames);
		}
	}, [masPreguntas]);

	/**
	 * Se tiene que de manera consecutiva y no mediante las otras funciones porque si no, se actualizan los steps dos veces y en el segundo paso (añadir o eliminar el otro step) se hace siempre sobre los steps antiguos y no funciona de la manera esperada.
	 *
	 * @param stepToRemove
	 * @param stepToAdd
	 */
	const removeAndAddStepAtTheSameTime = (stepToRemove: string, stepToAdd: string) => {
		const { steps } = store.getState().formInfo;
		setLoading(true);
		const removedSteps = removeMultipleStepsAndResetScreenIndex(steps, [stepToRemove]);
		const addedSteps = addingStepsLogic([stepToAdd], removedSteps);
		const finalSteps = resetStepsScreenIndex(addedSteps);
		handleNextStep();
		updateSteps(finalSteps);
		setLoading(false);
	};

	const handleShowMotivo = (nameOfMotivoStep: string, value?: string) => {
		if (value) {
			if (Number(value) == -1) value = 'Sin respuesta';
			if (isLowRating(value)) {
				addSteps([nameOfMotivoStep]);
			} else if (isHighRating(value) || isNoRating(value)) {
				removeSteps([nameOfMotivoStep]);
				delete formData.fields[stepNameToFieldName(nameOfMotivoStep)];
			}
		}
	};

	useEffect(() => {
		handleShowMotivo('SatisfaccionTratoMotivo', satisfaccionTrato);
	}, [satisfaccionTrato]);

	useEffect(() => {
		handleShowMotivo('SatisfaccionOrganizacionMotivo', satisfaccionOrganizacion);
	}, [satisfaccionOrganizacion]);

	useEffect(() => {
		handleShowMotivo('SatisfaccionAsesorMotivo', satisfaccionAsesor);
	}, [satisfaccionAsesor]);

	useEffect(() => {
		const stepName = store.getState().formInfo?.step?.name;

		// Verificamos que se ha contestado SatisfaccionConcesion
		if (stepName == 'SatisfaccionConcesion') {

			const ratings = [satisfaccionTrato, satisfaccionOrganizacion, satisfaccionAsesor];
			const { mostrarQlast1, mostrarQlast2 } = LastQuestionFlow(ratings, satisfaccionConcesion);

			if (mostrarQlast1 && mostrarQlast2) {
				addSteps(['SatisfaccionConcesionMotivo', 'AspectoSatisfaccionMotivo']);
			}
			if (!mostrarQlast1 && !mostrarQlast2) {
				removeSteps(['SatisfaccionConcesionMotivo', 'AspectoSatisfaccionMotivo']);
				delete formData.fields.satisfaccionConcesionMotivo;
				delete formData.fields.sspectoSatisfaccionMotivo;
			}
			if (!mostrarQlast1 && mostrarQlast2) {
				removeAndAddStepAtTheSameTime('SatisfaccionConcesionMotivo', 'AspectoSatisfaccionMotivo');
				delete formData.fields.satisfaccionConcesionMotivo;
			}
			if (mostrarQlast1 && !mostrarQlast2) {
				removeAndAddStepAtTheSameTime('AspectoSatisfaccionMotivo', 'SatisfaccionConcesionMotivo');
				delete formData.fields.aspectoSatisfaccionMotivo;
			}
		}
	}, [satisfaccionTrato, satisfaccionOrganizacion, satisfaccionAsesor, satisfaccionConcesion]);

	useEffect(() => {
		if(testDrive == '1') {
			addSteps(['SatisfaccionTestDrive', 'SatisfaccionTestDriveMotivo']);
		}
		else if(testDrive) {
			removeSteps(['SatisfaccionTestDrive', 'SatisfaccionTestDriveMotivo']);
		}
	}, [testDrive]);

	useEffect(() => {
		handleShowMotivo('SatisfaccionTestDriveMotivo', satisfaccionTestDrive);
	}, [satisfaccionTestDrive]);

	useEffect(() => {
		if (!installation) return null;

		const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo(getStepsVenta(getInstallationName(), getVehicleName()));

		const NuevoFormInfo: Partial<FormInfo> = {
			formStarted: true,
			sendFormCallback: sendForm,
			fullScreen: firstStep.fullScreen ? true : false,
			lastStep,
			numberOfScreens,
			formTheme: 'main',
			notALayer: true,
			formType: 'inPage',
			screenIndex: firstStep.screenIndex,
			step: firstStep,
			steps: newSteps ? newSteps : getStepsVenta(getInstallationName(), getVehicleName()),
			stepsHistory,
			thankYouComponent: <ThankYouCEM />,
			hideLegal: idPerson.LSSI__c === 'PERMITE' ? true : false,
		};

		initializeForm(NuevoFormInfo, NuevoFormData);
	}, [installation]);

	return !loading ? <FormController steps={getStepsVenta(getInstallationName(), getVehicleName())} screenType="full-screen" /> : null;
}
