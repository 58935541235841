import React, { useEffect, useRef, useState } from 'react';
import { createPincodeEntity } from '../dps-utils';
import { dpsSend, oneformBFFNdpsSend } from 'src/bff';
import { DpsData, EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { formDataBuilder, formatHeaders } from '../format-fields';
import { GenericErrorLayer, LoadingEncuestas } from 'src/components';
import {
	getParamsForm,
	useFeatureServices,
	removeWhiteSpace,
	useNavigate,
	useTrackingManager,
	getTrackingForm,
} from 'src/feature-app';
import { FormDataGroup, IdPerson, OneFormState } from 'src/types';
import { useDispatch, useStore } from 'react-redux';
import { TestIDForm } from '../FormsAdhoc/TestID2023/TestIDForm';
import { PlanIdealForm } from '../FormsAdhoc/2023PlanIdeal/PlanIdealForm';
import { ThankYouAutosubmit } from './ThankYouAutosubmit';

export enum ActionType {
	ClubVWUnsubscribeEmail = 'clubvw-unsubscribe-email',
	NewsletterElectricos = 'newsletter-electricos',
	NewsletterElectricosAdhoc2023TestID = 'newsletter-electricos-adhoc-2023-test_id',
	NewsletterElectricosAdhoc2023PlanIdeal = 'newsletter-electricos-adhoc-2023-plan_ideal',
}

interface AutosubmitControllerProps {
	action: string;
}

export function AutosubmitController({ action }: AutosubmitControllerProps): JSX.Element | null {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [showThankYou, setShowThankYou] = useState(false);
	const [renderTestID, setRenderTestID] = useState(false);
	const [renderPlanIdeal, setRenderPlanIdeal] = useState(false);

	const dpsDataRef = useRef(null);
	const idPersonRef = useRef(null);
	const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;

	const trackingManager = useTrackingManager();
	const dispatch = useDispatch();
	const { navigateTo } = useNavigate();
	const store = useStore();
	const { formData }: OneFormState = store.getState();

	let initialData: Partial<FormDataGroup> = {};

	if (action == ActionType.ClubVWUnsubscribeEmail) {
		initialData = {
			headers: {
				DPS_TOKEN: 'VW_DDB_CLUB_UNSUSCRIBE_EMAIL',
				FORM_OBJECTIVE: 'Club Volkswagen',
				FORM_NAME: 'vw-oneform-' + action + '-autosubmit',
			},
			fields: {
				formName: action + '-autosubmit',
				formType: 'newsletter',
				pageCategory: 'Club Volkswagen',
			},
		};
	} else {
		initialData = {
			headers: {
				DESC_BRAND: 'VW',
				DESC_GDPR_ADAPTED: 'true',
				DESC_TOKEN: 'VW_DDB_FRM_NEWSLETTER_ELECTRICOS',
				DESC_FORMOBJECTIVE: 'Suscripción Newsletter',
				COD_FORM_ID: 'vw-oneform-' + action + '-autosubmit',
			},
			fields: {
				formName: action + '-autosubmit',
				formType: 'newsletter',
				pageCategory: 'Suscripción Newsletter',
			},
		};
	}

	dispatch({ type: 'UPDATE_FORM', payload: { ...initialData } });

	const sendNewsletterElectricosForm = (): Promise<boolean> => {
		const idPerson: IdPerson = idPersonRef.current;

		const { fields } = formData;
		return new Promise<any>(async (resolve, reject) => {
			const { tc, today, url, device } = getParamsForm();

			const data = {
				...formatHeaders(initialData.headers),
				'headers[DESC_URLORIGIN]': url,
				'headers[DESC_CAMPAIGNBLOCK]': '4. VW Newsletter',
				'headers[DESC_DEVICETYPE]': device,
				'headers[DESC_ID_CAMPAIGN]': '7011n000000SLXMAA4',
				'headers[FECHA_INSERTION_DATE]': today,
				'headers[TC]': tc,
				'headers[DESC_VN_VO]': 'VN',

				'fields[DESC_NAME]': idPerson?.FirstName ? idPerson.FirstName : null,
				'fields[DESC_SURNAME_1]': idPerson?.LastName ? idPerson.LastName : null,

				'fields[DESC_FORM_VERSION]': `vw-oneform-${initialData.fields.formName}-${process.env.VERSION}`,
				'fields[DESC_EMAIL_ADDRESS]':
					idPerson && idPerson.TXT_Email__c ? idPerson.TXT_Email__c : removeWhiteSpace(fields.email),
				'fields[DESC_LOPD]': 'NO INFORMADO',
				'fields[DESC_TRANF_DATA_THIRD]': 'NO INFORMADO',
				'fields[DESC_LOPD_GDPR_PERFILADO]': 'NO INFORMADO',
				'fields[DESC_LSSI]': 'NO INFORMADO',
			};
			const dataStringified = JSON.stringify(data);
			const dataParsed = JSON.parse(dataStringified);
			const formData = formDataBuilder(dataParsed);
			const response = await dpsSend(formData);

			if (response && response.data?.content?.code == '200') {
				dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
				trackingManager.trackFormSubmissionSuccessMessageLoad(
					{
						contentId: undefined,
					},
					getTrackingForm(response),
				);
				setLoading(false);
				switch (action) {
					case ActionType.NewsletterElectricos:
						navigateTo('inscripcion-newsletter-electricos');
						//setShowThankYou(true);
						break;
					case ActionType.NewsletterElectricosAdhoc2023TestID: // Render de form Test ID como continuación
						setRenderTestID(true);
						break;
					case ActionType.NewsletterElectricosAdhoc2023PlanIdeal: // Render de Plan Ideal como continuación
						setRenderPlanIdeal(true);
						break;
				}
			} else {
				handleError();
			}
		});
	};

	const sendClubVWUnsubscribeForm = (): Promise<boolean> => {
		const idPerson: IdPerson = idPersonRef.current;
		const dpsData: DpsData = dpsDataRef.current;

		return new Promise<any>(async (resolve, reject) => {
			const { tc, today, url, device } = getParamsForm();

			const data = {
				...formatHeaders(initialData.headers),
				'headers[FORM_DATE_INSERTION]': today,
				'headers[FORM_DETAILS]': 'Unsubscribe Email',
				'headers[FORM_URL]': url,
				'headers[ORIGIN_DEVICE_TYPE]': device,
				'headers[ORIGIN_TRACKING_CODE]': tc,
				'headers[SF_CAMPAIGN_ID]': 'PENDIENTE',
				'headers[SF_CAMPAIGN_NAME]': 'PENDIENTE',
				//'headers[PINCODE]': dpsData.pincode.pincodeResponse.params.pincode,
				'headers[FK_SYSTEM]': idPerson && idPerson.Id ? idPerson.Id : '',

				'fields[DPS_BRAND]': 'Volkswagen',
				'fields[PERSON_NAME]': idPerson && idPerson.FirstName ? idPerson.FirstName : '',
				'fields[PERSON_SURNAME_1]': idPerson && idPerson.LastName ? idPerson.LastName : '',
				'fields[PERSON_SURNAME_2]': idPerson && idPerson.MiddleName ? idPerson.MiddleName : '',
				'fields[PERSON_EMAIL]': idPerson && idPerson.PersonEmail ? idPerson.PersonEmail : '',
				'fields[PERSON_PHONE_MOBILE]': idPerson && idPerson.PersonMobilePhone ? idPerson.PersonMobilePhone : '',

				'fields[PERSON_RGPD_COM_COMERCIALES]': 'NO INFORMADO',
				'fields[PERSON_RGPD_PERFILADO]': 'NO INFORMADO',

				'flags[PERSON_RGPD_ADAPTED]': 'true',
			};
			const dataStringified = JSON.stringify(data);
			const dataParsed = JSON.parse(dataStringified);
			const formData = formDataBuilder(dataParsed);
			const response = await oneformBFFNdpsSend(formData);

			if (response && response.data?.status?.code == 200) {
				dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
				resolve(true);
				trackingManager.trackFormSubmissionSuccessMessageLoad(
					{
						contentId: undefined,
					},
					getTrackingForm(response),
				);
				setLoading(false);
				navigateTo('clubvw-unsubscribe-email-thankyou');
				//setShowThankYou(true);
			} else {
				handleError();
				resolve(false);
			}
		});
	};

	const handleComplete = () => {
		const dpsData = oneFormService.getDpsData();
		const idPerson = createPincodeEntity(dpsData, 'PERSON');
		trackingManager.trackFormLayerLoad();
		dpsDataRef.current = dpsData;
		idPersonRef.current = idPerson;

		if (action == ActionType.ClubVWUnsubscribeEmail) {
			if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
				handleError();
			} else if (dpsData.pincode.pincodeUsed) {
				handleError();
			} else {
				sendClubVWUnsubscribeForm();
			}
		} else if (action == ActionType.NewsletterElectricos) {
			if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
				handleError();
			} else if (dpsData.pincode.pincodeUsed) {
				handleError();
			} else {
				sendNewsletterElectricosForm();
			}
		} else {
			sendNewsletterElectricosForm(); // Presubmit para mostrar forms adhoc
			setLoading(false);
		}
	};

	const handleError = () => {
		setError(true);
		setLoading(false);
	};

	useEffect(() => {
		const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

		oneFormCoreDpsIsLoaded.subscribe({
			next(value) {},
			error(err) {
				console.error(err);
				handleError();
			},
			complete() {
				handleComplete();
			},
		});
	}, []);

	if (loading) {
		return <LoadingEncuestas />;
	} else if (error) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, algo ha pasado"
					bodycopy="Por alguna razón no hemos podido realizar la petición."
					notShowCTA={true}
					icon={false}
					notALayer={true}
				/>
			</>
		);
	} else if (showThankYou) {
		return <ThankYouAutosubmit action={action} />;
	} else if (renderTestID) {
		const idPerson: IdPerson = idPersonRef.current;

		return <TestIDForm dpsData={dpsDataRef.current} idPerson={idPerson} />;
	} else if (renderPlanIdeal) {
		const idPerson: IdPerson = idPersonRef.current;

		return <PlanIdealForm dpsData={dpsDataRef.current} idPerson={idPerson} />;
	}
	return null;
}
