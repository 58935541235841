import React from 'react';
import { FeatureAppConfig } from 'src/feature-app';
import {
	BajaComunicacionesTrigger,
	CallMeEmbeddedTrigger,
	CitaPosventaDealerTrigger,
	CitaPosventaEmbeddedTrigger,
	CitaPosventaTrigger,
	DiaDeLaMadreTrigger,
	EmpresasTrigger,
	EncuestasTrigger,
	EurocopaTrigger,
	FidelizacionTrigger,
	LeadDealerTrigger,
	LeadEmbededTrigger,
	LeadInPageTrigger,
	LeadTrigger,
	MGMTrigger,
	NavidadTrigger,
	NewsletterTrigger,
	ProspectEmbeddedTrigger,
	ProspectPosventaEmbedded,
	ProspectsTrigger,
	RaceTrigger,
	SACTrigger,
	WidgetTrigger,
	SuvperheroesTrigger,
	AutosubmitTrigger,
	ConcursoRodajeTrigger,
	PlanIdealTrigger,
	FeedbackTrigger,
	TestIDTrigger,
	NavidadGTI2024Trigger,
} from 'src/triggers';

export interface TriggerProps {
	setCTALabel?: React.Dispatch<React.SetStateAction<string>>;
	setSelectedFocusJourney?: React.Dispatch<React.SetStateAction<JSX.Element>>;
	selectedFocusJourney?: JSX.Element;
	setSelectedJourney?: (selectedJourney: JSX.Element, skipTrackButtonClick?: boolean) => void;
}

export const getTrigger = (
	config: FeatureAppConfig,
	setSelectedJourney: (selectedJourney: JSX.Element, skipTrackButtonClick?: boolean) => void,
	setCTALabel: React.Dispatch<React.SetStateAction<string>>,
	selectedJourney: JSX.Element,
	setSelectedFocusJourney: React.Dispatch<React.SetStateAction<JSX.Element>>,
	selectedFocusJourney: JSX.Element,
	triggerLoaded: React.MutableRefObject<string>,
): JSX.Element => {
	if (!config.trigger) {
		throw new Error('No trigger in Feature App Config.');
	}

	switch (config.trigger) {
		case 'cita-posventa':
		case 'cita-posventa-tile-grid':
		case 'cita-posventa-tile':
			triggerLoaded.current = config.trigger;
			return (
				<CitaPosventaTrigger
					setSelectedJourney={setSelectedJourney}
					setCTALabel={setCTALabel}
					setSelectedFocusJourney={setSelectedFocusJourney}
					selectedFocusJourney={selectedFocusJourney}
				/>
			);
		case 'cita-posventa-embedded':
			triggerLoaded.current = 'cita-posventa-embedded';
			return <CitaPosventaEmbeddedTrigger setSelectedJourney={setSelectedJourney} />;
		case 'prospect-posventa-embedded':
			triggerLoaded.current = 'prospect-posventa-embed';
			return <ProspectPosventaEmbedded setSelectedJourney={setSelectedJourney} />;
		case 'cita-posventa-dealer':
			triggerLoaded.current = 'cita-posventa-dealer';
			return <CitaPosventaDealerTrigger setSelectedJourney={setSelectedJourney} />;
		case 'lead':
			triggerLoaded.current = 'lead';
			return <LeadTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'lead-embedded':
			triggerLoaded.current = 'lead-embedded';
			return <LeadEmbededTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'callme-embedded':
			triggerLoaded.current = 'callme-embedded';
			return <CallMeEmbeddedTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'lead-dealer':
			triggerLoaded.current = 'lead-dealer';
			return <LeadDealerTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'race':
			triggerLoaded.current = 'race';
			return <RaceTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'newsletter':
			triggerLoaded.current = 'newsletter';
			return <NewsletterTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'empresas':
			triggerLoaded.current = 'empresas';
			return <EmpresasTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'mgm':
			triggerLoaded.current = 'mgm';
			return <MGMTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'sac':
			triggerLoaded.current = 'sac';
			return <SACTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'adhoc-2024-navidad_gti':
			triggerLoaded.current = 'adhoc-2024-navidad_gti';
			return <NavidadGTI2024Trigger />;
		case 'adhoc-2021-diamadre':
			triggerLoaded.current = 'adhoc-2021-diamadre';
			return <DiaDeLaMadreTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'adhoc-2021-eurocopa':
			triggerLoaded.current = 'adhoc-2021-eurocopa';
			return <EurocopaTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'adhoc-2021-navidad':
			triggerLoaded.current = 'adhoc-2021-navidad';
			return <NavidadTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'adhoc-2021-fidelizacion_clientes':
			triggerLoaded.current = 'adhoc-2021-fidelizacion';
			return <FidelizacionTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'adhoc-2023-test_id':
			triggerLoaded.current = 'adhoc-2023-test_id';
			return <TestIDTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'adhoc-2023-concurso_rodaje':
			triggerLoaded.current = 'adhoc-2023-concurso_rodaje';
			return <ConcursoRodajeTrigger />;
		case 'adhoc-2023-plan_ideal':
			triggerLoaded.current = 'adhoc-2023-plan_ideal';
			return <PlanIdealTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'adhoc-suvperheroes':
			triggerLoaded.current = 'adhoc-suvperheroes';
			return <SuvperheroesTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'prospect':
			triggerLoaded.current = 'prospect';
			return <ProspectsTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'prospect-embedded':
			triggerLoaded.current = 'prospect-embedded';
			return <ProspectEmbeddedTrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />;
		case 'widget':
			triggerLoaded.current = 'widget';
			return <WidgetTrigger setSelectedJourney={setSelectedJourney} />;
		case 'baja-comunicaciones':
			triggerLoaded.current = 'baja-comunicaciones';
			return <BajaComunicacionesTrigger setSelectedJourney={setSelectedJourney} />;
		case 'encuestas':
			triggerLoaded.current = 'encuestas';
			return <EncuestasTrigger />;
		case 'lead-inpage':
			triggerLoaded.current = 'lead-inpage';
			return <LeadInPageTrigger />;
		case 'autosubmit':
			triggerLoaded.current = 'autosubmit';
			return <AutosubmitTrigger />;
		case 'feedback':
			triggerLoaded.current = 'feedback';
			return <FeedbackTrigger setSelectedJourney={setSelectedJourney} />;
		/*
    case 'adhoc-lanzamiento-taigo':
      triggerLoaded.current = 'adhoc-lanzamiento-taigo';
      return (
        <>
          <GlobalStyle />
          <LanzamientoTaigoTrigger setSelectedJourney={setSelectedJourney} />
        </>
      );
    */
		default:
			throw new Error("The config trigger defined in CMS doesn't match with any trigger in One Form");
	}
};
