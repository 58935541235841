import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { createStore, Store } from 'redux';
import { OneFormTrigger } from 'src/triggers';
import { OneFormState } from 'src/types';
import { useIsDevStage, useFeatureAppConfig, useFaContent, useFeatureServices } from 'src/feature-app';
import { initialState, oneFormReducer } from './redux';
import { composeWithDevTools } from '@redux-devtools/extension';

declare global {
	var s: { persCmp?: string; };
}

export const store: Store<OneFormState> = createStore(oneFormReducer, initialState, composeWithDevTools());
export default function App() {
	const [loading, setLoading] = useState(true);
	const oneFormService = useFeatureServices()['es:oneform-core'];
	const faContent = useFaContent();
	const config = useFeatureAppConfig();
	const isDevStage = useIsDevStage();

	useEffect(() => {
		let useBff = false;
		if (faContent && faContent.encuesta &&
			['LEM', 'NO COMPRA', 'NO RENOVACIÓN', 'SAC', 'NEWSLETTER', 'CEM - VENTA', 'CEM - POSVENTA', 'CEM - VENTA PRODUCTO', 'CEM - SEGUNDA ENTREGA - INVITACION', 'CEM - SEGUNDA ENTREGA - VALORACION'].includes(faContent.encuesta)) {
			useBff = true;
		}
		else if (faContent && faContent.action &&
			['clubvw-unsubscribe-email'].includes(faContent.action)) {
			useBff = true;
		}
		else if (config && config.trigger &&
			['baja-comunicaciones'].includes(config.trigger)) {
			useBff = true;
		}

		oneFormService.initializeService(true, useBff); // Necesario para saltarse la comprobación del login en los formularios en los que no sea necesario.

		const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

		oneFormCoreDpsIsLoaded.subscribe({
			next(value) { },
			error(err) { },
			complete() {
				// One Form no se inicializa hasta que no se han cargado todos los datos.
				const { person } = oneFormService.get();

				if (person) {
					store.dispatch({ type: 'UPDATE_FIELDS', payload: { ...person } });
					store.dispatch({
						type: 'SET_FORM_INFO',
						payload: { userIsAuthenticated: person.userIsAuthenticated === 'true' ? true : false },
					});
				}
				// setObjectValues(person, methods.setValue);
				if (isDevStage) {
					console.log('%cOneForm Version:', 'font-weight: 700', process.env.VERSION);
					console.log('%cOneForm Feature App Content:', 'font-weight: 700', faContent);
					console.log('%cOneForm Feature App Config:', 'font-weight: 700', config);
				}
				setLoading(false);
			},
		});
	}, []);
	return <Provider store={store}>{loading ? null : <OneFormTrigger />}</Provider>;
}
