import React, { useEffect, useState } from 'react';
import {
  Breakpoints,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Layout,
  Text,
  TextAlignment,
  ThemeProvider,
  Spinner,
  Divider,
  LayoutRowGap,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { Mail, Phone, CarPickupService, FleetServicePrivate } from 'src/icons-core-imports';
import { useGetDealer, useFeatureServices, LayerManagerWrapper, useFeatureAppEnvironment, useNavigate } from 'src/feature-app';
import { NuevaCitaPosventaFormController } from '../forms/CitaPosventa/NuevaCitaPosventa';
import { MapGeneralController, NewMap } from 'src/feature-app/NewMap';
import { useSelector, useStore, useDispatch } from 'react-redux';
import { DealersData, OneFormState } from 'src/types';
import { GenericErrorLayer } from 'src/components/layers';
import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';

interface CitaPosventaDEalerTriggerProps {
  setSelectedJourney: any;
  setCTALabel?: any;
}

export const CitaPosventaDealerTrigger = (props: CitaPosventaDEalerTriggerProps) => {
  const { dealerName, selectedDealer, loading } = useGetDealer();
  const { setSelectedJourney } = props;
  const { points } = useSelector((state: OneFormState) => state.dealersInfo);
  const [error, setError] = useState(false);
  const [dealerInfo, setDealerInfo] = useState(null);
  const [dealerDmsInfo, setDealerDmsInfo] = useState(null);
  const [pointsLodaded, setPointsLoaded] = useState(false);
  const layerManager = useFeatureServices()['layer-manager'];
  const store = useStore();
  const env = useFeatureAppEnvironment();
  const {navigateTo} = useNavigate();
  const dispatch = useDispatch();

  const renderErrorScreen = () => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <GenericErrorLayer
          title="Uy, hay un error con este Servicio Oficial"
          bodycopy="Parece que ahora mismo no podemos cargar los datos del taller que has elegido. ¿Sigues queriendo reservar un cita?"
          ctaLayer="Pide cita en tu taller"
          icon={false}
          ctaTextAlign='left'
          // urlHref='https://www.volkswagen.es/es/clientes/cita-previa.html'
          onClick={() => {
            navigateTo('es-posventa-cita_previa');
          }}
        />
      </LayerManagerWrapper>
    );
  };

  const handleErrorLayer = () => {
    const layer = layerManager.openFocusLayer(renderErrorScreen, {}, { size: FocusLayerSizeV2.A });

    return layer;
  };

  // la altura del mapa en  375 debe ser = al width, mientras que en 560 tiene que ser 50% del width

  const initializeState = (selectedDealer: DealersData) => {
    if (selectedDealer) {
      if (selectedDealer.dmsInfo.dmsAvailable === true) {
        setDealerDmsInfo(selectedDealer.dmsInfo);
      }
      setDealerInfo(selectedDealer.markerInfo.dealerInfo);
    } else {
      setError(true);
      handleErrorLayer();
    }
  };

  useEffect(() => {
    if (points) {
      setPointsLoaded(true);
    }
  }, [points]);

  useEffect(() => {
    if (!loading) {
      initializeState(selectedDealer);
    }
  }, [selectedDealer, loading]);

  return error ? null : (
    <ThemeProvider theme="main">
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
            { name: '.', columns: 2 },
            { name: 'b', columns: 20 },
            { name: '.', columns: 2 },
          ],
        }}
      >
        <Container
          padding={{ bottom: ContainerPadding.dynamic0250 }}
          horizontalAlign={'center'}
        >
          <Text appearance={TokenTextAppearance.headline400} textAlign={TextAlignment.center}>
            <Text bold>¿Quieres visitar nuestro </Text>Servicio Oficial?
          </Text>
        </Container>
      </Layout>
      {dealerInfo && pointsLodaded ? (
        <Layout
          appearance={{
            [Breakpoints.default]: [
              { name: '.', columns: 2 },
              { name: 'a', columns: 20 },
              { name: '.', columns: 2 },
              { name: '.', columns: 2 },
              { name: 'b', columns: 20 },
              { name: '.', columns: 2 },
            ],
            [Breakpoints.b960]: [
              { name: '.', columns: 2 },
              { name: 'a', columns: 9 },
              { name: '.', columns: 1 },
              { name: 'b', columns: 10 },
              { name: '.', columns: 2 },
            ],
            [Breakpoints.b1600]: [
              { name: '.', columns: 2 },
              { name: 'a', columns: 9 },
              { name: '.', columns: 1 },
              { name: 'b', columns: 10 },
              { name: '.', columns: 2 },
            ],
            [Breakpoints.b1920]: [
              { name: '.', columns: 3 },
              { name: 'a', columns: 7 },
              { name: '.', columns: 2 },
              { name: 'b', columns: 9 },
              { name: '.', columns: 3 },
            ],
            [Breakpoints.b2560]: [
              { name: '.', columns: 4 },
              { name: 'a', columns: 6 },
              { name: '.', columns: 2 },
              { name: 'b', columns: 8 },
              { name: '.', columns: 4 },
            ],
          }}
          rowGap={LayoutRowGap.dynamic0250}
        >
          <div>
            <Container wrap={'always'}>
              <Container padding={{ bottom: ContainerPadding.static400 }}>
                <Text appearance={TokenTextAppearance.headline300} bold>
                  {dealerDmsInfo ? dealerDmsInfo.name : dealerInfo.name}
                </Text>
              </Container>
              <Container gutter={ContainerGutter.static500} wrap={'always'}>
                <CTA
                  tag="button"
                  emphasis="primary"
                  onClick={(e: any) => {
                    e.preventDefault();
                    initializeState(selectedDealer);
                    setSelectedJourney(<NuevaCitaPosventaFormController isCitaPosventaDealer={true} />);
                  }}
                  ariaLabel="aria label"
                >
                  Pide tu cita
                </CTA>
                <Container wrap={'always'} gutter={ContainerGutter.dynamic0050}>
                  <Text appearance={TokenTextAppearance.headline200} bold>
                    Dirección
                  </Text>
                  <Text appearance={TokenTextAppearance.copy200}>
                    {dealerInfo.address}, {dealerInfo.zipCode} {dealerInfo.city}
                  </Text>
                </Container>
                <Divider />
                <Container wrap={'always'} gutter={ContainerGutter.static350}>
                  <Text appearance={TokenTextAppearance.headline200} bold>
                    Datos de contacto
                  </Text>
                  <div style={{ display: 'flex' }}>
                    <div style={{ paddingRight: '24px', width: 'fit-content', color: '#001E50' }}>
                      <Phone />
                    </div>
                    <CTA
                      tag="a"
                      emphasis="tertiary"
                      href={`tel:+34${dealerDmsInfo ? dealerDmsInfo.phone : dealerInfo.phone}`}
                      ariaLabel="aria label"
                    >
                      <b> {dealerDmsInfo ? dealerDmsInfo.phone : dealerInfo.phone} </b>
                    </CTA>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ paddingRight: '24px', width: 'fit-content', color: '#001E50' }}>
                      <Mail />
                    </div>
                    <CTA
                      tag="a"
                      emphasis="tertiary"
                      href={`mailto:${dealerDmsInfo ? dealerDmsInfo.email : dealerInfo.mail}`}
                      ariaLabel="aria label"
                    >
                      <b>{dealerDmsInfo ? dealerDmsInfo.email : dealerInfo.mail}</b>
                    </CTA>
                  </div>
                </Container>
                {!dealerDmsInfo ? null : (
                  <>
                    {dealerDmsInfo.pickupCarServiceAvailabe || dealerDmsInfo.replacementCarServiceAvailable ? (
                      <Container gutter={ContainerGutter.static500} wrap={'always'}>
                        <Divider />
                        <Container wrap={'always'} gutter={ContainerGutter.static350}>
                          <Text appearance={TokenTextAppearance.headline200} bold>
                            {' '}
                            Servicios adicionales{' '}
                          </Text>
                          {dealerDmsInfo.pickupCarServiceAvailabe ? (
                            <div style={{ display: 'flex' }}>
                              <div style={{ paddingRight: '24px', width: 'fit-content', color: '#001E50' }}>
                                <CarPickupService />
                              </div>
                              <Text appearance={TokenTextAppearance.copy200}> Servicio de recogida y entrega </Text>
                            </div>
                          ) : null}
                          {dealerDmsInfo.replacementCarServiceAvailable ? (
                            <div style={{ display: 'flex' }}>
                              <div style={{ paddingRight: '24px', width: 'fit-content', color: '#001E50' }}>
                                <FleetServicePrivate />
                              </div>
                              <Text appearance={TokenTextAppearance.copy200}> Coche de sustitución </Text>
                            </div>
                          ) : null}
                        </Container>
                      </Container>
                    ) : null}
                  </>
                )}
              </Container>
            </Container>
          </div>
          <MapGeneralController dealer={selectedDealer} skipMapMovementOnClick>
            <NewMap isTrigger={true} />
          </MapGeneralController>
        </Layout>
      ) : (
        <Container
          padding={{ bottom: ContainerPadding.dynamic0250 }}
          horizontalAlign={'center'}
        >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner variant="large" />
            <Text>Cargando los datos de la instalación</Text>
          </div>
        </Container>
      )}
    </ThemeProvider>
  );
};
