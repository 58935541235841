import React from 'react';
import { Breakpoints, Layout, LayoutRowGap, Text, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import { CarlineSelect, PantallaNombreApellido, MultipleCTASelection, RatingStars, PantallaIntroduccionStars, DetalleMotivo, } from 'src/components';
import { Steps } from 'src/types';
import { RadioTextGroup } from 'src/components/inputs/RadioTextGroup';
import { ConfirmacionCem } from 'src/forms/Cem/ConfirmacionCem';
import { CTAsSiNo, CTAsSiNoSinRespuesta } from 'src/feature-app/InputsValues/CTAsObjects';
import { FirstStepCEM } from 'src/forms/Cem/FirstStepCEM';
import { valueWrongConcesion, valueWrongMarca, valueWrongModelo, valueWrongPersona } from './helpers';

function OneElementTemplateFullScreen(props: any) {
	const { element, allowOverflowingContent } = props;
	return (
		<Layout
			allowOverflowingContent
			appearance={{
				[Breakpoints.default]: [
					{ name: '.', columns: 2 },
					{ name: 'a', columns: 20 },
					{ name: '.', columns: 2 },
				],
				[Breakpoints.b960]: [
					{ name: '.', columns: 3 },
					{ name: 'a', columns: 18 },
					{ name: '.', columns: 3 },
				],
				[Breakpoints.b1280]: [
					{ name: '.', columns: 5 },
					{ name: 'a', columns: 14 },
					{ name: '.', columns: 5 },
				],
				[Breakpoints.b1920]: [
					{ name: '.', columns: 6 },
					{ name: 'a', columns: 12 },
					{ name: '.', columns: 6 },
				],
				[Breakpoints.b2560]: [
					{ name: '.', columns: 7 },
					{ name: 'a', columns: 10 },
					{ name: '.', columns: 7 },
				],
			}}
			rowGap={LayoutRowGap.static300}
		>
			{element}
		</Layout>
	);
}

export const getStepsVenta = (installation: string, vehicle: string) => {

	const ctaDatosIncorrectos = [
		{ 
			label: 'Marca incorrecta', 
			value: valueWrongMarca.toString() 
		},
		{ 
			label: 'Modelo incorrecto', 
			value: valueWrongModelo.toString() },
		{
			label: 'Punto de venta incorrecto',
			value: valueWrongConcesion.toString(),
		},
		{
			label: 'Persona de contacto incorrecta',
			value: valueWrongPersona.toString(),
		},
	];

	const optionsEnviarConcesion = [
		{
			value: '3',
			label: 'Sí, mis respuestas pueden ser enviadas y analizadas junto a mi nombre y al número de bastidor de mi vehículo, para el alcance descrito anteriormente (el consentimiento puede revocarse en cualquier momento a través de los canales de contacto que se indican a continuación*)',
			info: '*Puedo revocar este consentimiento en cualquier momento sin dar motivos a mi Distribuidor para mis datos personalizados.',
		},
		{
			value: '2',
			label: 'Mis respuestas pueden ser enviadas pero sin mencionar mi nombre y al número de bastidor de mi vehículo',
			info: 'Seleccionando esta opción, el punto de venta no podrá ponerse en contacto contigo en relación a tus comentarios.',
		},
		{
			value: '0',
			label: 'Mis respuestas solo deben ser analizadas de manera anónima',
			info: 'Seleccionando esta opción, tus respuestas serán analizadas de modo agregado junto al resto de respuestas de todos los entrevistados y no se trasladarán a los comentarios abiertos.',
		},
	];

	const optionsPublicarRespuestas = [
		{
			label: 'Sí, mis respuestas pueden ser publicadas sin mi nombre en la Web Oficial Volkswagen y en la de ' + installation,
			value: '1',
		},
		{
			label: 'No, mis respuestas no pueden ser publicadas en la Web Oficial Volkswagen y en la de ' + installation,
			value: '2',
		},
	];

	let index = 0;
	const stepsVenta: Steps[] = [
		{
			fields: <FirstStepCEM />,
			screenIndex: index++,
			name: 'DatosCorrectos',
			alternateTitle: <Text>¿Puedes confirmar que estos datos son correctos?</Text>,
			outputs: ['datosCorrectos'],
			outputsText: ['datosCorrectos'],
			trueOrFalseQuestion: true,
		},
		{
			title: (
				<>
					Por favor, <Text bold>¿puedes confirmar qué dato no es correcto?</Text>
				</>
			),
			fields: (
				<OneElementTemplateFullScreen
					element={<MultipleCTASelection ctaValues={ctaDatosIncorrectos} name="datosIncorrectos" nextOnClick />}
				/>
			),
			screenIndex: index++,
			name: 'DatosIncorrectos',
			outputs: ['datosIncorrectos'],
			outputsText: ['datosIncorrectos'],
			ctas: ctaDatosIncorrectos,
		},
		{
			title: <Text bold>¿Compraste tu vehículo en {installation}?</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={<MultipleCTASelection ctaValues={CTAsSiNo} name="confirmacionConcesion" nextOnClick ctaWidth="81" />}
				/>
			),
			screenIndex: index++,
			name: 'ConfirmacionConcesion',
			outputs: ['confirmacionConcesion'],
			outputsText: ['confirmacionConcesion'],
		},
		{
			title: <Text bold>¿Cuál es el modelo correcto?</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<div style={{ marginBottom: '2px' }}>
							<CarlineSelect isCEMVenta useCarline />
						</div>
					}
				/>
			),
			screenIndex: index++,
			name: 'Modelo',
			outputs: ['modelo'],
			outputsText: ['modelo'],
		},
		{
			title: (
				<>
					A continuación,{' '}
					<Text bold>
						indica tu nivel de satisfacción con cada uno de estos aspectos relacionados con la compra de tu coche.
					</Text>
				</>
			),
			fields: <PantallaIntroduccionStars bodyCopy="Valora del 1 al 5, a mayor número de estrellas más satisfacción." />,
			screenIndex: index++,
			name: 'Explicacion',
		},
		{
			title: <Text bold>El trato recibido</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<RatingStars
							name="satisfaccionTrato"
							nextOnClick={true}
							textGood="satisfecho/a"
							textBad="insatisfecho/a"
							CTASinRespuesta
						/>
					}
				/>
			),
			screenIndex: index++,
			name: 'SatisfaccionTrato',
			outputs: ['satisfaccionTrato'],
			outputsText: ['satisfaccionTrato'],
			outputsIsStars: true,
		},
		{
			title: <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<DetalleMotivo
							required={true}
							name="satisfaccionTratoMotivo"
							showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados."
							numberOfMaxLength={300}
							CTALabel="Sin respuesta"
						/>
					}
				/>
			),
			screenIndex: index++,
			name: 'SatisfaccionTratoMotivo',
			outputs: ['satisfaccionTratoMotivo'],
			outputsText: ['satisfaccionTratoMotivo'],
		},
		{
			title: <Text bold>La organización del punto de venta</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<div style={{ paddingTop: '8px' }}>
							<Text appearance={TokenTextAppearance.copy200} staticSize>
								A la hora de establecer una cita o entregarte el coche, por ejemplo.
							</Text>
							<RatingStars
								name="satisfaccionOrganizacion"
								nextOnClick={true}
								textGood="satisfecho/a"
								textBad="insatisfecho/a"
								CTASinRespuesta
							/>
						</div>
					}
				/>
			),
			screenIndex: index++,
			name: 'SatisfaccionOrganizacion',
			outputs: ['satisfaccionOrganizacion'],
			outputsText: ['satisfaccionOrganizacion'],
			outputsIsStars: true,
		},
		{
			title: <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<DetalleMotivo
							required={true}
							name="satisfaccionOrganizacionMotivo"
							showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados."
							numberOfMaxLength={300}
							CTALabel="Sin respuesta"
						/>
					}
				/>
			),
			screenIndex: index++,
			name: 'SatisfaccionOrganizacionMotivo',
			outputs: ['satisfaccionOrganizacionMotivo'],
			outputsText: ['satisfaccionOrganizacionMotivo'],
		},
		{
			title: <Text bold>El asesoramiento recibido por parte del asesor comercial Volkswagen</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<RatingStars
							name="satisfaccionAsesor"
							nextOnClick={true}
							textGood="satisfecho/a"
							textBad="insatisfecho/a"
							CTASinRespuesta
						/>
					}
				/>
			),
			screenIndex: index++,
			name: 'SatisfaccionAsesor',
			outputs: ['satisfaccionAsesor'],
			outputsText: ['satisfaccionAsesor'],
			outputsIsStars: true,
		},
		{
			title: <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<DetalleMotivo
							required={true}
							name="satisfaccionAsesorMotivo"
							showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados."
							numberOfMaxLength={300}
							CTALabel="Sin respuesta"
						/>
					}
				/>
			),
			screenIndex: index++,
			name: 'SatisfaccionAsesorMotivo',
			outputs: ['satisfaccionAsesorMotivo'],
			outputsText: ['satisfaccionAsesorMotivo'],
		},
		{
			title: <Text bold>¿Cuál es tu nivel de satisfacción con nuestros servicios prestados como punto de venta?</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<div style={{ paddingTop: '8px' }}>
							<Text appearance={TokenTextAppearance.copy200} staticSize>
								Pensando en el proceso de compra en general de tu nuevo vehículo.
							</Text>
							<RatingStars
								name="satisfaccionConcesion"
								nextOnClick={true}
								textGood="satisfecho/a"
								textBad="insatisfecho/a"
								CTASinRespuesta
							/>
						</div>
					}
				/>
			),
			screenIndex: index++,
			name: 'SatisfaccionConcesion',
			outputs: ['satisfaccionConcesion'],
			outputsText: ['satisfaccionConcesion'],
			outputsIsStars: true,
		},
		{
			title: <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<DetalleMotivo
							required={true}
							name="satisfaccionConcesionMotivo"
							showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados."
							numberOfMaxLength={300}
							CTALabel="Sin respuesta"
						/>
					}
				/>
			),
			screenIndex: index++,
			name: 'SatisfaccionConcesionMotivo',
			outputs: ['satisfaccionConcesionMotivo'],
			outputsText: ['satisfaccionConcesionMotivo'],
		},
		{
			title: <Text bold>¿Hay algún aspecto con el que no estuvieras satisfecho y que no hayas mencionado aún?</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<DetalleMotivo
							required={true}
							name="aspectoSatisfaccionMotivo"
							showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados."
							numberOfMaxLength={300}
							CTALabel="Sin respuesta"
						/>
					}
				/>
			),
			screenIndex: index++,
			name: 'AspectoSatisfaccionMotivo',
			outputs: ['aspectoSatisfaccionMotivo'],
			outputsText: ['aspectoSatisfaccionMotivo'],
		},
		{
			title: <Text bold>¿El asesor comercial te ofreció realizar una prueba de conducción?</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={<MultipleCTASelection ctaValues={CTAsSiNoSinRespuesta} name="testDrive" nextOnClick />}
				/>
			),
			screenIndex: index++,
			name: 'TestDrive',
			outputs: ['testDrive'],
			outputsText: ['testDrive'],
			ctas: CTAsSiNoSinRespuesta,
		},
		{
			title: <Text bold>¿Cuál es tu nivel de satisfacción con la prueba de conducción?</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<div style={{ paddingTop: '8px' }}>
							<Text appearance={TokenTextAppearance.copy200} staticSize>
								Pensando en el proceso de compra en general de tu nuevo vehículo.
							</Text>
							<RatingStars
								name="satisfaccionTestDrive"
								nextOnClick={true}
								textGood="satisfecho/a"
								textBad="insatisfecho/a"
								CTASinRespuesta
							/>
						</div>
					}
				/>
			),
			screenIndex: index++,
			name: 'SatisfaccionTestDrive',
			outputs: ['satisfaccionTestDrive'],
			outputsText: ['satisfaccionTestDrive'],
			outputsIsStars: true,
		},
		{
			title: <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<DetalleMotivo
							required={true}
							name="satisfaccionTestDriveMotivo"
							showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados."
							numberOfMaxLength={300}
							CTALabel="Sin respuesta"
						/>
					}
				/>
			),
			screenIndex: index++,
			name: 'SatisfaccionTestDriveMotivo',
			outputs: ['satisfaccionTestDriveMotivo'],
			outputsText: ['satisfaccionTestDriveMotivo'],
		},
		{
			title: (
				<>
					<Text bold>
						¿Te han ofrecido una segunda cita después de la entrega para darte más información sobre el funcionamiento de tu coche?
					</Text>
				</>
			),
			fields: (
				<OneElementTemplateFullScreen
					element={<MultipleCTASelection ctaValues={CTAsSiNoSinRespuesta} name="informacionUsoVehiculo" nextOnClick />}
				/>
			),
			screenIndex: index++,
			name: 'InformacionUsoVehiculo',
			outputs: ['informacionUsoVehiculo'],
			outputsText: ['informacionUsoVehiculo'],
			trueOrFalseQuestion: true,
			ctas: CTAsSiNoSinRespuesta,
		},
		{
			title: (
				<>
					<Text bold>
						¿Te ha contactado {installation} para preguntarte si estás satisfecho con tu {vehicle} después de entregártelo?
					</Text>
				</>
			),
			fields: (
				<OneElementTemplateFullScreen
					element={<MultipleCTASelection ctaValues={CTAsSiNoSinRespuesta} name="contactoSatisfaccion" nextOnClick />}
				/>
			),
			screenIndex: index++,
			name: 'ContactoSatisfaccion',
			outputs: ['contactoSatisfaccion'],
			outputsText: ['contactoSatisfaccion'],
			trueOrFalseQuestion: true,
			ctas: CTAsSiNoSinRespuesta,
		},
		{
			title: (
				<>
					Finalmente nos gustaría saber si podemos <Text bold>enviar tus respuestas junto con tu nombre al punto de venta {installation} y el bastidor del vehículo, y puedan usarlos para análisis adicionales.</Text>
				</>
			),
			fields: <OneElementTemplateFullScreen element={<RadioTextGroup name="enviarConcesion" options={optionsEnviarConcesion} />} />,
			screenIndex: index++,
			name: 'EnviarConcesion',
			outputs: ['enviarConcesion'],
			outputsText: ['enviarConcesion'],
			ctas: optionsEnviarConcesion,
		},
		{
			title: (
				<>
					Por favor, <Text bold>introduce o comprueba tus datos:</Text>
				</>
			),
			fields: (
				<OneElementTemplateFullScreen
					element={
						<div style={{ paddingBottom: '2px' }}>
							<PantallaNombreApellido />
						</div>
					}
				/>
			),
			screenIndex: index++,
			name: 'DatosPersonales',
			outputs: ['name', 'surname', 'secondSurname'],
			outputsText: ['name', 'surname', 'secondSurname'],
		},
		{
			title: (
				<Text bold>
					¿Podríamos publicar tus respuestas en la web oficial de Volkswagen y en la de {installation} sin mencionar tu nombre?
				</Text>
			),
			fields: (
				<OneElementTemplateFullScreen
					element={
						<div style={{ paddingTop: '8px' }}>
							<Text appearance={TokenTextAppearance.copy200} staticSize>
								Para compartir tu experiencia con otros clientes.
							</Text>
							<RadioTextGroup name="publicarRespuestas" options={optionsPublicarRespuestas} />
						</div>
					}
				/>
			),
			screenIndex: index++,
			name: 'PublicarRespuestas',
			outputs: ['publicarRespuestas'],
			outputsText: ['publicarRespuestas'],
			ctas: optionsPublicarRespuestas,
		},
		{
			fields: <ConfirmacionCem />,
			screenIndex: index++,
			name: 'Confirmacion',
			outputs: ['confirmacion'],
			outputsText: ['confirmacion'],
		},
	];

	return stepsVenta;
};
