import React, { useEffect, useState } from 'react';
import {
  Breakpoints,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Layout,
  LayoutRowGap,
  Spinner,
  Text,
  TextAlignment,
  ThemeProvider,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { Calc, Calendar, Info, Interior } from 'src/icons-core-imports';
import {
  LayerManagerWrapper,
  OneFormContent,
  TriggerProps,
  useFaContent,
  useFeatureAppEnvironment,
  useFeatureServices,
  useGetInstalaciones,
  useNavigate,
} from 'src/feature-app';
import { MapGeneralController, NewMap } from 'src/feature-app/NewMap';
import { useSelector, useStore, useDispatch } from 'react-redux';
import { Installation, OneFormState } from 'src/types';
import { GenericErrorLayer } from 'src/components/layers';
import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';
import { useGetInstalacion } from 'src/feature-app/hooks/use-get-instalacion';
import { CitaVentaForm, MasInformacionForm, PresupuestoForm, TestDriveForm } from 'src/forms';

export const LeadDealerTrigger = (props: TriggerProps) => {

  const installations = useGetInstalaciones('VW_DDB_FRM_LEM_REC_LEM');
  const { selectedDealer, loading } = useGetInstalacion();
  const { setSelectedJourney, setCTALabel } = props;
  const { points } = useSelector((state: OneFormState) => state.dealersInfo);
  const layerManager = useFeatureServices()['layer-manager'];
  const { navigateTo } = useNavigate();

  const faContent = useFaContent();
  const [error, setError] = useState(false);
  const [dealerInfo, setDealerInfo] = useState<Installation | null>(null);
  const [pointsLodaded, setPointsLoaded] = useState(false);

  const store = useStore();
  const env = useFeatureAppEnvironment();
  const dispatch = useDispatch();


  const handleLayerOpen = (journey: JSX.Element, label: string) => {
    dispatch({ type: 'RESET_STATE' });
    setCTALabel(label);
    setSelectedJourney(journey);
  };

  const openPresupuestoLayer = () => {
    handleLayerOpen(<PresupuestoForm isLeadDealer />, 'Pide presupuesto');
  };
  const openMasInformacionLayer = () => {
    handleLayerOpen(<MasInformacionForm isLeadDealer />, 'Pide más información');
  };
  const openTestDriveLayer = () => {
    handleLayerOpen(<TestDriveForm isLeadDealer />, 'Pruébalo');
  };
  const openCitaVentaLayer = () => {
    handleLayerOpen(<CitaVentaForm isLeadDealer />, 'Pide cita');
  };

  const renderErrorScreen = () => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <GenericErrorLayer
          title="Uy, hay un error con este Servicio Oficial"
          bodycopy="Parece que ahora mismo no podemos cargar los datos del taller que has elegido. ¿Sigues queriendo reservar un cita?"
          ctaLayer="Pide cita en tu taller"
          icon={false}
          ctaTextAlign='left'
          // urlHref='https://www.volkswagen.es/es/clientes/cita-previa.html'
          onClick={() => {
            navigateTo('es-posventa-cita_previa');
          }}
        />
      </LayerManagerWrapper>
    );
  };

  const handleErrorLayer = () => {
    const layer = layerManager.openFocusLayer(renderErrorScreen, {}, { size: FocusLayerSizeV2.A });

    return layer;
  };

  const thereIsOnlyOneForm = (faContent: OneFormContent) => faContent && faContent.forms && faContent.forms.length === 1;


  // la altura del mapa en  375 debe ser = al width, mientras que en 560 tiene que ser 50% del width

  const initializeState = (selectedDealer: Installation) => {
    if (selectedDealer) {
      setDealerInfo(selectedDealer);
    } else {
      setError(true);
      handleErrorLayer();
    }
  };

  useEffect(() => {
    if (points) {
      setPointsLoaded(true);
    }
  }, [points]);

  useEffect(() => {
    if (!loading) {
      initializeState(selectedDealer);
    }
  }, [loading]);


  const renderTitle = (mobile: boolean) => {
    return (
      <Text appearance={mobile ? TokenTextAppearance.headline300 : TokenTextAppearance.headline400} textAlign={mobile ? TextAlignment.left : TextAlignment.center}>
        <Text bold>¿Quieres contactar </Text>con este punto de venta?
      </Text>
    )
  }

  const renderTestDriveCta = (strechContent?: boolean) => {
    let icon = <Interior variant="small" />;

    if (thereIsOnlyOneForm(faContent)) {
      icon = null;
    }

    return (
      <CTA icon={icon} tag="button" onClick={(e) => openTestDriveLayer()} stretchContent={strechContent}>
        Pruébalo
      </CTA>
    );
  };

  const renderMasInformacionCta = (strechContent?: boolean) => {
    let icon = <Info variant="small" />;

    if (thereIsOnlyOneForm(faContent)) {
      icon = null;
    }

    return (
      <CTA icon={icon} tag="button" onClick={(e) => openMasInformacionLayer()} stretchContent={strechContent}>
        <span style={{ whiteSpace: 'nowrap' }}>Pide más información</span>
      </CTA>
    );
  };

  const renderPidePresupuestoCta = (strechContent?: boolean) => {
    let icon = <Calc variant="small" />;

    if (thereIsOnlyOneForm(faContent)) {
      icon = null;
    }

    return (
      <CTA icon={icon} tag="button" onClick={(e) => {
        e.preventDefault();

        openPresupuestoLayer()
      }
      } stretchContent={strechContent} >
        Pide presupuesto
      </CTA >
    );
  };

  const renderPideCitaCta = (strechContent?: boolean) => {
    let icon = <Calendar variant="small" />;

    if (thereIsOnlyOneForm(faContent)) {
      icon = null;
    }

    return (
      <CTA icon={icon} tag="button" onClick={(e) => openCitaVentaLayer()} stretchContent={strechContent}>
        Pide cita en tu punto de venta
      </CTA>
    );
  };


  return error ? null : (
    <ThemeProvider theme="main">
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
            { name: '.', columns: 2 },
            { name: 'b', columns: 20 },
            { name: '.', columns: 2 },
          ],
        }}
      >
        <Container
          padding={{ bottom: ContainerPadding.dynamic0250 }}
          horizontalAlign={'center'}
        >
          {renderTitle(window.innerWidth < 590)}
        </Container>
      </Layout>
      {dealerInfo && pointsLodaded ? (
        <Layout
          appearance={{
            [Breakpoints.default]: [
              { name: '.', columns: 2 },
              { name: 'a', columns: 20 },
              { name: '.', columns: 2 },
              { name: '.', columns: 2 },
              { name: 'b', columns: 20 },
              { name: '.', columns: 2 },
            ],
            [Breakpoints.b960]: [
              { name: '.', columns: 2 },
              { name: 'a', columns: 9 },
              { name: '.', columns: 1 },
              { name: 'b', columns: 10 },
              { name: '.', columns: 2 },
            ],
            [Breakpoints.b1600]: [
              { name: '.', columns: 2 },
              { name: 'a', columns: 9 },
              { name: '.', columns: 1 },
              { name: 'b', columns: 10 },
              { name: '.', columns: 2 },
            ],
            [Breakpoints.b1920]: [
              { name: '.', columns: 3 },
              { name: 'a', columns: 7 },
              { name: '.', columns: 2 },
              { name: 'b', columns: 9 },
              { name: '.', columns: 3 },
            ],
            [Breakpoints.b2560]: [
              { name: '.', columns: 4 },
              { name: 'a', columns: 6 },
              { name: '.', columns: 2 },
              { name: 'b', columns: 8 },
              { name: '.', columns: 4 },
            ],
          }}
          rowGap={LayoutRowGap.dynamic0250}
        >
          <div>
            <Container wrap={'always'}>
              <Container padding={{ bottom: ContainerPadding.static400 }}>
                <Text appearance={TokenTextAppearance.headline300} bold>
                  {dealerInfo.VGED_CommercialName__c}
                </Text>
              </Container>
              <Container gutter={ContainerGutter.static500} wrap={'always'}>
                <Container gutter={ContainerGutter.dynamic0100} wrap={'always'}>
                  {renderPidePresupuestoCta()}
                  {renderMasInformacionCta()}
                  {renderTestDriveCta()}
                  {renderPideCitaCta()}
                </Container>
                <Container wrap={'always'} gutter={ContainerGutter.dynamic0050}>
                  <Text appearance={TokenTextAppearance.headline200} bold>
                    Dirección
                  </Text>
                  <Text appearance={TokenTextAppearance.copy200}>
                    {dealerInfo.ShippingStreet}, {dealerInfo.ShippingPostalCode} {dealerInfo.ShippingCity}
                  </Text>
                </Container>
              </Container>
            </Container>
          </div>
          <MapGeneralController dealer={selectedDealer} skipMapMovementOnClick>
            <NewMap isTrigger={true} />
          </MapGeneralController>
        </Layout>
      ) : (
        <Container
          padding={{ bottom: ContainerPadding.dynamic0250 }}
          horizontalAlign={'center'}
        >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner variant="large" />
            <Text>Cargando los datos de la instalación</Text>
          </div>
        </Container>
      )}
    </ThemeProvider>
  );
};
