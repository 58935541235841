import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import {
	FormController,
	isHighRating,
	isLowRating,
	isNoRating,
	useOneFormContext,
} from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { DpsData } from 'src/feature-service/es-oneform-core-feature-service/types';
import { LastQuestionFlow } from 'src/forms/Cem';
import {
	getStepsVentaProducto,
	ThankYouCEMVentaProducto,
} from 'src/forms/Cem/CEMVentaProducto/';
import {
	checkUndefinedsInData,
	formatSinRespuestaValue,
	getCemDate,
	getSfAddressRest,
	sanitizeKvps,
	stepNameToFieldName,
	valueWrongConcesion,
	valueWrongMarca,
	valueWrongModelo,
	valueWrongPersona,
} from 'src/forms/Cem/helpers';
import { formatHeaders, formDataBuilder } from 'src/forms/format-fields';
import {
	addRemovedStep,
	getArrayOfStepsNames,
	getIndexInSteps,
	removeMultipleStepsAndResetScreenIndex,
	resetStepsScreenIndex,
} from 'src/forms/helpers/add-remove-steps';
import { formatToTwoChars, strTitlelize } from 'src/helpers';
import {
	FormDataGroup,
	FormInfo,
	IdDealer,
	IdOrder,
	IdPerson,
	IdVehicle,
	OneFormState,
	Steps,
} from 'src/types';

export interface CEMVentaProductoFormProps {
	idOrder?: IdOrder;
	idPerson: IdPerson;
	idVehicle: IdVehicle;
	idDealer?: IdDealer;
	dpsData: DpsData;
	installation?: IdDealer;
}

export function CEMVentaProductoForm(props: CEMVentaProductoFormProps) {
	const { idOrder, idPerson, idVehicle, idDealer, dpsData, installation } =
		props;
	const {
		initializeForm,
		handleNextStep,
		handleShowFinalScreen,
		setShowPreviousStep,
		handleScreenChange,
	} = useOneFormContext();
	const { formData } = useSelector((state: OneFormState) => state);
	const store = useStore();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const urlParams = new URLSearchParams(window.location.search);
	const adviser = urlParams.get('adviser');
	const agency = urlParams.get('agency');
	const dev = urlParams.get('dev') !== null;

	const {
		isDatosCorrectos,
		datoIncorrecto,
		confirmacionConcesion,
		satisfaccionInterior,
		satisfaccionExterior,
		satisfaccionSoftware,
		satisfaccionCalidad,
		satisfaccionConduccion,
		satisfaccionTecnologia,
		satisfaccionInfo,
		satisfaccionMultimedia,
		satisfaccionPrecio,
		satisfaccionCargaPublica,
		satisfaccionCargaCasa,
		satisfaccionGeneral,
		modelo,
		enviarConcesion,
		masPreguntas,
	} = formData.fields;

	const isElectrico = idVehicle.VGED_ModelGroup__c.substring(0, 2) == 'ID';

	const modeloRef = useRef(null); // Necesitamos almacenar el modelo ya que cada vez que el usuario selecciona un modelo se modifican los steps, por lo que necesitamos una referencia para saber que modelo hay que sustituir.

	const getInstallationName = () => {
		return strTitlelize(
			installation?.VGED_CommercialName__c  
				? installation.VGED_CommercialName__c 
				: idDealer?.VGED_CommercialName__c 
				? idDealer.VGED_CommercialName__c 
				: ''
		);
	};

	const NuevoFormData: Partial<FormDataGroup> = {
		headers: {
			FORM_DETAILS: 'CEM Venta Producto',
			FORM_OBJECTIVE: 'Encuesta',
			DPS_TOKEN: 'VW_DDB_FRM_CEM_PRODUCTO_2025',
			FORM_ID: 'Email_B04_C01_CEM_Product',
		},
		fields: {
			formName: 'cem-venta-producto',
			formType: 'otherform',
			pageCategory: 'Encuesta',
			name: idPerson.FirstName,
			surname: idPerson.LastName,
			secondSurname: idPerson.MiddleName,
			installation: getInstallationName(),
		}, // Analítica
	};

	const sendSurvey = () => {
		const { formData }: OneFormState = store.getState();
		const { fields } = formData;

		return !(
			// negation
			(
				(fields.isDatosCorrectos == 'false' &&
					fields.datoIncorrecto == valueWrongMarca) ||
				(fields.isDatosCorrectos == 'false' &&
					fields.datoIncorrecto == valueWrongPersona) ||
				(fields.isDatosCorrectos == 'false' &&
					fields.datoIncorrecto == valueWrongConcesion &&
					fields.confirmacionConcesion == 'false')
			)
		);
	};

	const getData = (addSurvey?: boolean) => {
		const { formData }: OneFormState = store.getState();
		const { fields, headers } = formData;
		const { tc, today, url, device } = getParamsForm();

		const data = {
			...formatHeaders(headers),
			'headers[FORM_NAME]': `vw-oneform-${fields.formName}`,
			'headers[FORM_VERSION]': `vw-oneform-${fields.formName}-${process.env.VERSION}`,
			'headers[FORM_URL]': url,
			'headers[FORM_DATE_INSERTION]': today,
			'headers[ORIGIN_DEVICE_TYPE]': device,
			'headers[ORIGIN_TRACKING_CODE]': tc,
			'headers[PINCODE]': dpsData.pincode.pincodeResponse.params.pincode,
			'headers[FK_SYSTEM]': idOrder.VGED_ClimaproOrderID__c,

			'fields[DPS_BRAND]': 'Volkswagen',
			'fields[SF_LEAD_INSTALLATION_ID]': installation.Id,
			'fields[SF_LEAD_INSTALLATION_NAME]': installation.VGED_CommercialName__c,
			'fields[SF_LEAD_INSTALLATION_CODE]': installation.VGED_DealerCode__c,
			'fields[SF_LEAD_INSTALLATION_CODE_KVPS]': sanitizeKvps(
				installation.KVPSCode__c,
			),
			'fields[VEH_ACTUAL_FRAME]': idVehicle.Name,
			'fields[VEH_ACTUAL_PLATE]': idVehicle.VGED_RegistrationPlate__c,
			'fields[VEH_ACTUAL_MODEL]': idVehicle.VGED_ModelGroup__c
				? idVehicle.VGED_ModelGroup__c
				: dpsData.model
				? dpsData.model
				: null,
			'fields[PERSON_NAME]': fields.name,
			'fields[PERSON_SURNAME_1]': fields.surname,
			'fields[PERSON_SURNAME_2]': fields.secondSurname,
			'fields[PERSON_PHONE_MOBILE]': idPerson.PersonMobilePhone,
			'fields[PERSON_EMAIL]': idPerson.PersonEmail,
			'fields[PERSON_POSTAL_ROAD_NAME]': getSfAddressRest(
				idPerson?.PersonMailingAddress,
				'street',
			),
			'fields[PERSON_POSTAL_CP]': getSfAddressRest(
				idPerson.PersonMailingAddress,
				'postalCode',
			),
			'fields[PERSON_POSTAL_LOCATION]': getSfAddressRest(
				idPerson?.PersonMailingAddress,
				'city',
			),
			'fields[PERSON_POSTAL_PROVINCE_CODE]': formatToTwoChars(
				getSfAddressRest(idPerson.PersonMailingAddress, 'stateCode'),
			),
			'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
			'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi
				? fields.lssi
				: 'NO INFORMADO',

			'fields[SURVEY_ANSWER_01]': fields.isDatosCorrectos == 'true' ? '1' : '2',
			'fields[SURVEY_ANSWER_02]':
				fields.isDatosCorrectos == 'false' ? fields.datoIncorrecto : null,
			'fields[SURVEY_ANSWER_03]':
				fields.isDatosCorrectos == 'false' && fields.datoIncorrecto == 2
					? fields.modelo
					: null,
			'fields[SURVEY_ANSWER_04]':
				fields.isDatosCorrectos == 'false' && fields.datoIncorrecto == 3
					? fields.confirmacionConcesion == 'true'
						? '1'
						: '2'
					: null,
			//conditional send
			'fields[SURVEY_ANSWER_32]': adviser,
			'fields[SURVEY_ANSWER_34]': fields.enviarConcesion,

			'flags[PERSON_RGPD_ADAPTED]': 'true',
			'flags[FLAG_RELACION_MARCA_SIN_IC]': 'false',
			'flags[FLAG_AGENCY]': agency == 'AGENCIA' ? 'true' : 'false',
		};

		if (sendSurvey() || addSurvey) {
			data['fields[SURVEY_ANSWER_05]'] = formatSinRespuestaValue(
				fields.satisfaccionInterior,
			);
			data['fields[SURVEY_ANSWER_06]'] = formatSinRespuestaValue(
				fields.satisfaccionInteriorMotivo,
			);
			data['fields[SURVEY_ANSWER_07]'] = formatSinRespuestaValue(
				fields.satisfaccionExterior,
			);
			data['fields[SURVEY_ANSWER_08]'] = formatSinRespuestaValue(
				fields.satisfaccionExteriorMotivo,
			);
			data['fields[SURVEY_ANSWER_09]'] = formatSinRespuestaValue(
				fields.satisfaccionSoftware,
			);
			data['fields[SURVEY_ANSWER_10]'] = formatSinRespuestaValue(
				fields.satisfaccionSoftwareMotivo,
			);
			data['fields[SURVEY_ANSWER_11]'] = formatSinRespuestaValue(
				fields.satisfaccionCalidad,
			);
			data['fields[SURVEY_ANSWER_12]'] = formatSinRespuestaValue(
				fields.satisfaccionCalidadMotivo,
			);
			data['fields[SURVEY_ANSWER_13]'] = formatSinRespuestaValue(
				fields.satisfaccionConduccion,
			);
			data['fields[SURVEY_ANSWER_14]'] = formatSinRespuestaValue(
				fields.satisfaccionConduccionMotivo,
			);
			data['fields[SURVEY_ANSWER_15]'] = formatSinRespuestaValue(
				fields.satisfaccionTecnologia,
			);
			data['fields[SURVEY_ANSWER_16]'] = formatSinRespuestaValue(
				fields.satisfaccionTecnologiaMotivo,
			);
			data['fields[SURVEY_ANSWER_17]'] = formatSinRespuestaValue(
				fields.satisfaccionGeneral,
			);
			data['fields[SURVEY_ANSWER_18]'] = formatSinRespuestaValue(
				fields.satisfaccionGeneralMotivo,
			);
			data['fields[SURVEY_ANSWER_19]'] = formatSinRespuestaValue(
				fields.satisfaccionExperiencia,
			);
			data['fields[SURVEY_ANSWER_20]'] = formatSinRespuestaValue(
				fields.satisfaccionEntrega,
			);
			data['fields[SURVEY_ANSWER_21]'] = formatSinRespuestaValue(
				fields.satisfaccionInfo,
			);
			data['fields[SURVEY_ANSWER_22]'] = formatSinRespuestaValue(
				fields.satisfaccionInfoMotivo,
			);
			data['fields[SURVEY_ANSWER_23]'] = formatSinRespuestaValue(
				fields.satisfaccionMultimedia,
			);
			data['fields[SURVEY_ANSWER_24]'] = formatSinRespuestaValue(
				fields.satisfaccionMultimediaMotivo,
			);
			data['fields[SURVEY_ANSWER_25]'] = formatSinRespuestaValue(
				fields.satisfaccionPrecio,
			);
			data['fields[SURVEY_ANSWER_26]'] = formatSinRespuestaValue(
				fields.satisfaccionPrecioMotivo,
			);
		}

		if ((sendSurvey() && isElectrico) || addSurvey) {
			data['fields[SURVEY_ANSWER_27]'] = formatSinRespuestaValue(
				fields.satisfaccionCargaPublica,
			);
			data['fields[SURVEY_ANSWER_28]'] = formatSinRespuestaValue(
				fields.satisfaccionCargaPublicaMotivo,
			);
			data['fields[SURVEY_ANSWER_33]'] = formatSinRespuestaValue(
				fields.satisfaccionCargaCasa,
			);
			data['fields[SURVEY_ANSWER_35]'] = formatSinRespuestaValue(
				fields.satisfaccionCargaCasaMotivo,
			);
		}

		if (
			idOrder.VGED_CEMDeliveryDate__c &&
			idOrder.VGED_CEMDeliveryDate__c != 'null'
		) {
			data['fields[CEM_DATE]'] = getCemDate(idOrder.VGED_CEMDeliveryDate__c);
		}

		return data;
	};

	useEffect(() => {
		if (dev) {
			console.log(getData(true));
		}
	}, [formData]);

	const sendForm = (): Promise<boolean> => {
		return new Promise<any>(async (resolve, reject) => {
			const dataStringified = JSON.stringify(getData());
			const dataParsed = JSON.parse(dataStringified);
			const formData = formDataBuilder(dataParsed);
			checkUndefinedsInData(getData(), 'Venta Producto');
			const response = await oneformBFFNdpsSend(formData);

			if (response && response.data?.status?.code == 200) {
				dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
				resolve(true);
			} else {
				resolve(false);
			}
		});
	};

	const replaceModelo = (string: string) => {
		if (modeloRef.current) {
			return string.replace(modeloRef.current, modelo);
		} else {
			return string.replace(idVehicle.VGED_ModelGroup__c, modelo);
		}
	};

	/**
	 * Se extrae la lógica para poder utilizarla por separado sin tener que usar las funciones de loading o updateSteps ya que hay una pregunta en la que podemos eliminar y añadir un step en el mismo paso.
	 * @param stepsToAdd
	 * @param alternativeSteps
	 * @return finalSteps
	 */
	const addingStepsLogic = (
		stepsToAdd: string[],
		alternativeSteps?: Steps[],
	) => {
		const { steps: actualSteps, screenIndex } = store.getState().formInfo;
		const stepsToUse = alternativeSteps ? alternativeSteps : actualSteps;
		// Hay que revertir los steps para que se añadan en el orden correcto
		const indexesInSteps = stepsToAdd
			.reverse()
			.map((step) => getIndexInSteps(stepsToUse, step));
		let newSteps = [...stepsToUse];

		indexesInSteps.forEach((index, i) => {
			if (index == -1) {
				newSteps = addRemovedStep(
					getStepsVentaProducto(isElectrico, getInstallationName()),
					stepsToUse,
					stepsToAdd[i],
					screenIndex,
				);
			}
		});
		const finalSteps = resetStepsScreenIndex(newSteps);
		return finalSteps;
	};

	/**
	 * Se tiene que de manera consecutiva y no mediante las otras funciones porque si no, se actualizan los steps dos veces y en el segundo paso (añadir o eliminar el otro step) se hace siempre sobre los steps antiguos y no funciona de la manera esperada.
	 *
	 * @param stepToRemove
	 * @param stepToAdd
	 */
	const removeAndAddStep = (stepToRemove: string, stepToAdd: string) => {
		const { steps } = store.getState().formInfo;
		setLoading(true);
		const removedSteps = removeMultipleStepsAndResetScreenIndex(steps, [
			stepToRemove,
		]);
		const addedSteps = addingStepsLogic([stepToAdd], removedSteps);
		const finalSteps = resetStepsScreenIndex(addedSteps);
		handleNextStep();
		updateSteps(finalSteps);
		setLoading(false);
	};

	const addSteps = (stepsToAdd: string[]) => {
		setLoading(true);

		const finalSteps = addingStepsLogic(stepsToAdd);
		updateSteps(finalSteps);
		handleNextStep();

		setLoading(false);
	};

	const removeSteps = (stepsToDelete: string[]) => {
		const { steps } = store.getState().formInfo;
		setLoading(true);
		const finalSteps = removeMultipleStepsAndResetScreenIndex(
			steps,
			stepsToDelete,
		);
		updateSteps(finalSteps);
		setLoading(false);
	};

	const updateSteps = (steps: Steps[]) => {
		dispatch({ type: 'UPDATE_STEPS', payload: steps });
		dispatch({ type: 'UPDATE_LAST_STEP', payload: steps[steps.length - 1] });
		dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: steps.length });
	};

	const modifyTitleSteps = (
		steps: Steps[],
		replaceCallback: (s: string) => string,
	) => {
		return steps.map((step) => {
			if (!step.title) return step;
			if (Array.isArray(step.title.props.children)) {
				step.title.props.children = step.title.props.children.map(
					(child: any) => {
						if (typeof child == 'object') {
							if (typeof child.props.children == 'object') {
								child.props.children.props.children = replaceCallback(
									child.props.children.props.children,
								);
							} else {
								child.props.children = replaceCallback(child.props.children);
							}
						} else {
							child = replaceCallback(child);
						}
						return child;
					},
				);
			} else if (typeof step.title.props.children == 'object') {
				step.title.props.children.props.children = replaceCallback(
					step.title.props.children.props.children,
				);
			} else {
				step.title.props.children = replaceCallback(step.title.props.children);
			}
			return step;
		});
	};

	const sanitizeSteps = (steps: Steps[]): Steps[] => {
		return steps.filter((step) => step !== undefined);
	};

	const handleShowMotivo = (nameOfMotivoStep: string, value?: string) => {
		if (value) {
			if (Number(value) == -1) value = 'Sin respuesta';
			if (isLowRating(value)) {
				addSteps([nameOfMotivoStep]);
			} else if (isHighRating(value) || isNoRating(value)) {
				removeSteps([nameOfMotivoStep]);
				delete formData.fields[stepNameToFieldName(nameOfMotivoStep)];
			}
		}
	};

	/**
	 * Lógica steps datos correctos / incorrectos
	 */
	useEffect(() => {
		if (modelo) {
			const pastSteps = store.getState().formInfo.steps;
			const newSteps = modifyTitleSteps(pastSteps, replaceModelo);
			modeloRef.current = modelo;
			updateSteps(newSteps);
		}
	}, [modelo]);

	useEffect(() => {
		if (!isDatosCorrectos) {
			return;
		}
		if (isDatosCorrectos == 'true') {
			const steps = store.getState().formInfo.steps;
			const modelo = getIndexInSteps(steps, 'seleccionModelo');
			// En el caso de elegir que los datos son incorrectos, llegar a confirmación y volver atrás hay que regenerar todos los pasos.
			if (modelo == -1) {
				const initialSteps = store.getState().formInfo.initialSteps;
				const finalSteps = removeMultipleStepsAndResetScreenIndex(
					initialSteps,
					['datoIncorrecto', 'seleccionModelo', 'confirmacionConcesion'],
				);
				updateSteps(finalSteps);
			} else {
				removeSteps([
					'datoIncorrecto',
					'seleccionModelo',
					'confirmacionConcesion',
				]);
			}
		} else {
			addSteps(['datoIncorrecto', 'seleccionModelo', 'confirmacionConcesion']);
		}
	}, [isDatosCorrectos]);

	useEffect(() => {
		if (!datoIncorrecto) {
			return;
		}
		if (
			datoIncorrecto == valueWrongMarca ||
			datoIncorrecto == valueWrongPersona
		) {
			const pastSteps = store.getState().formInfo.steps;
			const newsteps = [
				pastSteps[0],
				pastSteps[1],
				pastSteps[pastSteps.length - 1],
			];
			const finalSteps = resetStepsScreenIndex(newsteps);
			updateSteps(finalSteps);
		}
		if (datoIncorrecto == valueWrongConcesion) {
			const initialSteps = store.getState().formInfo.initialSteps;
			const finalSteps = removeMultipleStepsAndResetScreenIndex(initialSteps, [
				'seleccionModelo',
			]);
			updateSteps(finalSteps);
		} else if (datoIncorrecto == valueWrongModelo) {
			const initialSteps = store.getState().formInfo.initialSteps;
			const finalSteps = removeMultipleStepsAndResetScreenIndex(initialSteps, [
				'confirmacionConcesion',
			]);
			updateSteps(finalSteps);
		}
	}, [datoIncorrecto]);

	useEffect(() => {
		if (!confirmacionConcesion) {
			return;
		}
		if (confirmacionConcesion == 'true') {
			const initialSteps = store.getState().formInfo.initialSteps;
			const finalSteps = removeMultipleStepsAndResetScreenIndex(initialSteps, [
				'seleccionModelo',
			]);
			updateSteps(finalSteps);
		} else {
			const pastSteps = store.getState().formInfo.steps;
			const newsteps = [
				pastSteps[0],
				pastSteps[1],
				pastSteps[2],
				pastSteps[pastSteps.length - 1],
			];
			const finalSteps = resetStepsScreenIndex(newsteps);
			updateSteps(finalSteps);
		}
	}, [confirmacionConcesion]);

	useEffect(() => {
		if (!enviarConcesion) {
			return;
		}
		if (enviarConcesion == '1') {
			addSteps(['datosPersonales']);
		} else {
			removeSteps(['datosPersonales']);
		}
	}, [enviarConcesion]);

	useEffect(() => {
		if (!masPreguntas) {
			return;
		}
		if (masPreguntas == 'true') {
			const stepNames = getArrayOfStepsNames(
				getStepsVentaProducto(isElectrico, getInstallationName()),
				'masPreguntas',
				'enviarConcesion',
			);
			addSteps(stepNames);
		} else if (masPreguntas == 'false') {
			const { steps } = store.getState().formInfo;
			const stepNames = getArrayOfStepsNames(
				steps,
				'masPreguntas',
				'enviarConcesion',
			);
			removeSteps(stepNames);
		}
	}, [masPreguntas]);

	/**
	 * Lógica steps preguntas aleatorias
	 */
	const randomizeSteps = (steps: Steps[]): Steps[] => {
		const firstRandomQuestionIndex = getIndexInSteps(
			steps,
			'satisfaccionInterior',
		); // Asignar el indice de la primera pregunta que entra en shuffle
		const groupedQuestions = [
			[
				getIndexInSteps(steps, 'satisfaccionInterior'),
				getIndexInSteps(steps, 'satisfaccionInteriorMotivo'),
			],
			[
				getIndexInSteps(steps, 'satisfaccionExterior'),
				getIndexInSteps(steps, 'satisfaccionExteriorMotivo'),
			],
			[
				getIndexInSteps(steps, 'satisfaccionSoftware'),
				getIndexInSteps(steps, 'satisfaccionSoftwareMotivo'),
			],
			[
				getIndexInSteps(steps, 'satisfaccionCalidad'),
				getIndexInSteps(steps, 'satisfaccionCalidadMotivo'),
			],
			[
				getIndexInSteps(steps, 'satisfaccionConduccion'),
				getIndexInSteps(steps, 'satisfaccionConduccionMotivo'),
			],
			[
				getIndexInSteps(steps, 'satisfaccionTecnologia'),
				getIndexInSteps(steps, 'satisfaccionTecnologiaMotivo'),
			],
			[
				getIndexInSteps(steps, 'satisfaccionInfo'),
				getIndexInSteps(steps, 'satisfaccionInfoMotivo'),
				getIndexInSteps(steps, 'satisfaccionMultimedia'),
				getIndexInSteps(steps, 'satisfaccionMultimediaMotivo'),
			],
			[
				getIndexInSteps(steps, 'satisfaccionPrecio'),
				getIndexInSteps(steps, 'satisfaccionPrecioMotivo'),
			],
		];

		const shuffledGroup = groupedQuestions.sort(() => Math.random() - 0.5);
		const flatShuffledGroup = shuffledGroup.flat();

		let shuffledSteps = [...steps];

		for (let i = 0; i < flatShuffledGroup.length; i++) {
			shuffledSteps[firstRandomQuestionIndex + i] = steps[flatShuffledGroup[i]];
		}

		if (dev) {
			console.log(shuffledSteps);
		}

		return sanitizeSteps(shuffledSteps);
	};

	/**
	 * Lógica steps mostrar motivos
	 */
	useEffect(() => {
		handleShowMotivo('satisfaccionInteriorMotivo', satisfaccionInterior);
	}, [satisfaccionInterior]);

	useEffect(() => {
		handleShowMotivo('satisfaccionExteriorMotivo', satisfaccionExterior);
	}, [satisfaccionExterior]);

	useEffect(() => {
		handleShowMotivo('satisfaccionSoftwareMotivo', satisfaccionSoftware);
	}, [satisfaccionSoftware]);

	useEffect(() => {
		handleShowMotivo('satisfaccionTecnologiaMotivo', satisfaccionTecnologia);
	}, [satisfaccionTecnologia]);

	useEffect(() => {
		handleShowMotivo('satisfaccionCalidadMotivo', satisfaccionCalidad);
	}, [satisfaccionCalidad]);

	useEffect(() => {
		handleShowMotivo('satisfaccionConduccionMotivo', satisfaccionConduccion);
	}, [satisfaccionConduccion]);

	useEffect(() => {
		handleShowMotivo('satisfaccionInfoMotivo', satisfaccionInfo);
	}, [satisfaccionInfo]);

	useEffect(() => {
		handleShowMotivo('satisfaccionMultimediaMotivo', satisfaccionMultimedia);
	}, [satisfaccionMultimedia]);

	useEffect(() => {
		handleShowMotivo('satisfaccionPrecioMotivo', satisfaccionPrecio);
	}, [satisfaccionPrecio]);

	useEffect(() => {
		handleShowMotivo(
			'satisfaccionCargaPublicaMotivo',
			satisfaccionCargaPublica,
		);
	}, [satisfaccionCargaPublica]);

	useEffect(() => {
		handleShowMotivo('satisfaccionCargaCasaMotivo', satisfaccionCargaCasa);
	}, [satisfaccionCargaCasa]);

	/**
	 * Lógica steps preguntas finales
	 */
	useEffect(() => {
		const stepName = store.getState().formInfo?.step?.name;

		// Verificamos que se ha contestado satisfaccionGeneral
		if (stepName == 'satisfaccionGeneral') {
			const ratings = [
				satisfaccionInterior,
				satisfaccionExterior,
				satisfaccionSoftware,
				satisfaccionCalidad,
				satisfaccionConduccion,
				satisfaccionTecnologia,
				satisfaccionInfo,
				satisfaccionMultimedia,
				satisfaccionPrecio,
			];
			if (isElectrico) {
				ratings.push(satisfaccionCargaPublica);
				ratings.push(satisfaccionCargaCasa);
			}

			const { mostrarQlast1, mostrarQlast2 } = LastQuestionFlow(
				ratings,
				satisfaccionGeneral,
			);

			if (mostrarQlast1 && mostrarQlast2) {
				addSteps(['satisfaccionGeneralMotivo', 'satisfaccionExperiencia']);
			}
			if (!mostrarQlast1 && !mostrarQlast2) {
				removeSteps(['satisfaccionGeneralMotivo', 'satisfaccionExperiencia']);
				delete formData.fields.satisfaccionGeneralMotivo;
				delete formData.fields.satisfaccionExperiencia;
			}
			if (!mostrarQlast1 && mostrarQlast2) {
				removeAndAddStep(
					'satisfaccionGeneralMotivo',
					'satisfaccionExperiencia',
				);
				delete formData.fields.satisfaccionGeneralMotivo;
			}
			if (mostrarQlast1 && !mostrarQlast2) {
				removeAndAddStep(
					'satisfaccionExperiencia',
					'satisfaccionGeneralMotivo',
				);
				delete formData.fields.satisfaccionExperiencia;
			}
		}
	}, [
		satisfaccionInterior,
		satisfaccionExterior,
		satisfaccionSoftware,
		satisfaccionCalidad,
		satisfaccionConduccion,
		satisfaccionTecnologia,
		satisfaccionInfo,
		satisfaccionMultimedia,
		satisfaccionPrecio,
		satisfaccionCargaPublica,
		satisfaccionCargaCasa,
		satisfaccionGeneral,
	]);

	/**
	 * Form constructor
	 */
	const getFormInfo = (steps: Steps[]) => {
		const randomizedSteps = randomizeSteps(steps);

		return {
			firstStep: randomizedSteps[0],
			lastStep: randomizedSteps[randomizedSteps.length - 1],
			stepsHistory: [0],
			numberOfScreens: randomizedSteps.length,
			newSteps: randomizedSteps,
		};
	};

	useEffect(() => {
		if (!installation) return null;

		const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } =
			getFormInfo(getStepsVentaProducto(isElectrico, getInstallationName()));

		const NuevoFormInfo: Partial<FormInfo> = {
			formStarted: true,
			sendFormCallback: sendForm,
			fullScreen: firstStep.fullScreen ? true : false,
			lastStep,
			numberOfScreens,
			formTheme: 'main',
			notALayer: true,
			formType: 'inPage',
			screenIndex: firstStep.screenIndex,
			step: firstStep,
			steps: newSteps ? newSteps : getStepsVentaProducto(isElectrico, getInstallationName()),
			stepsHistory,
			thankYouComponent: <ThankYouCEMVentaProducto idPerson={idPerson} />,
			hideLegal: idPerson.LSSI__c == 'PERMITE' ? true : false,
		};

		initializeForm(NuevoFormInfo, NuevoFormData);
	}, [installation]);

	return !loading ? (
		<FormController
			steps={getStepsVentaProducto(isElectrico, getInstallationName())}
			screenType="full-screen"
		/>
	) : null;
}
