import {Suggestion} from './feature-app';

export interface OneFormState {
	formData: FormDataGroup;
	formInfo: FormInfo;
	dealersInfo: DealersInfo;
	vehiclesInfo: VehiclesInfo;
}

export interface Preconfiguration {
	activo: number;
	activoMatch: number;
	activoOffers: boolean;
	capacidadMaletero: number[];
	carline: {
		code: number;
		name: string;
		text: string;
		title: string;
	};
	colores: {
		interior: string;
		exterior: {
			nombre: string;
			codigo: string;
			hexadecimal: string;
			invertido: number;
			atrevido: number;
			elegante: number;
		};
	};
	configToken: string;
	etiquetaDGT: string;
	conduccion: any;
	descripcion: string;
	descuentos: {[index: string]: number};
	destacados: any;
	equipamiento: string;
	id: number;
	marketingCode: string;
	llantas: string;
	marketingLongText: string;
	marketingShortText: string;
	espacio: any;
	motorizacion: string;
	pasajeros: any;
	plazas: number;
	precio: number;
	presupuesto: any;
	prioridad: number;
	tapiceria: string;
	titular: string;
	url: string;
	visible: boolean;
	title: string;
}
export interface RankedPreconfiguration extends Preconfiguration {
	operaciones?: any[];
	puntuacion?: number;
	puntuaciones?: number[];
	sumHighlights?: number;
	totalStyle?: number;
	affinity?: number;
	budgetOffset?: number;
}

export const isDealerData = (suggestion: Suggestion): suggestion is DealersData => {
	if (!suggestion) return null;
	return (suggestion as DealersData).dmsInfo !== undefined;
};
export interface DealersInfo {
	carlines: CarlineBase[];
	/**
	 * Al momento de enviar una cita, necesitamos esta información para mapear correctamente el modelo, por lo que lo tenemos que cargar desde el principio para que esté disponible en el momento del envio.
	 */
	citaCarlines: CarlineBase[];
	dealers: DealersData[];
	selectedCarline: CarlineBase;
	installations: Installation[];
	points: Points[];
	favouriteDealer: DealersData;
	/**
	 *  Lo necesitamos en el caso de Cita Posventa Dealer, si el usuario tiene una concesión favorita la puede sobreescribir y debe tener prioridad la que viene por la url;
	 */
	urlDealer: DealersData;
	selectedDealer: DealersData;
	selectedInstallation: Installation;
	dealersOrInstallationsMode: DealersOrInstallationsMode;
	oferta: Offer;
	salesGroup: SalesGroupInfo;
	match: RankedPreconfiguration;
}

export type DealersOrInstallationsMode = 'dealers' | 'installations';
export interface InstallationResponse {
	content: {attributes: DomainAttributesValues[]}[];
}

export interface InstallationEntregaIdealResponse {
	instalaciones: [];
}

export interface VehiclesInfo {
	brands: [];
	models: [];
	brandSelected: string;
	modelSelected: string;
}

export interface FormDataGroup {
	fields: FormDataFields;
	headers: FormDataHeaders;
	flags?: FormDataFlags;
}

export type Horario = 'before' | 'morning' | 'evening' | 'afternon';
export interface FormDataFields {
	name?: string;
	horario?: Horario;
	slot?: Slot;
	[key: string]: any;
	dealer?: any;
}

export interface FormDataHeaders {
	[key: string]: any;
}
export interface FormDataFlags {
	[key: string]: string;
}

export interface FormInfo {
	/**
	 * Flag necesario para que se dispare el journey con un dealer preseleccionado desde la url. En la primera carga omitimos la localización y el horario, pero en caso de que el
	 * usuario vuelva hacia atrás el journey debe seguir siendo siempre el mismo.
	 */
	firstMapLoad?: boolean;
	/**
	 * Flag que se activa cuando se produce el primer dispatch al crear el formulario, para no mostrar el form hasta que no se inicie el mismo.
	 */
	formStarted: boolean;
	/**
	 * Flag que se activa al llegar al final del formulario. Es necesario en caso de edición de la cita para pasar del paso a editar a la confirmación de la cita sin volver a hacer todo el journey.
	 */
	formEnded: boolean;
	/**
	 * Flag en caso de que uno de los steps necesite mostrarse en fullscreen sin los botones de navegación.
	 */
	formResponse?: any;

	/**
	 * Flag para determinar el tema de color del form
	 */
	formTheme?: 'main' | 'inverted';
	/**
	 * Flag para determinar si el form se despliega en un layer o no
	 */
	notALayer?: boolean;
	/**
	 * Flag para determinar el tipo de front de un formulario: layer, encuesta o module
	 */
	formType?: 'layer' | 'inPage' | 'module';

	/**
	 * Flag para determinar si hay que alinear al centro, para forms formato full screen cuando solo hay una pantalla
	 */
	alingItemsCenter?: boolean;
	/**
	 * Flag que indica que el formulario ya ha terminado y se ha enviado.
	 */
	formSubmitted: boolean;
	fullScreen: boolean;
	/**
	 * Objeto con los parámetros necesarios para inicializar el mapa a partir de la búsqueda del suggest
	 */
	geometry?: Geometry;
	/**
	 * Flag para controlar si hay un error que impide que se pueda seguir avanzando y se tenga que cerrar el layer.
	 */
	genericError: boolean;
	/**
	 * En caso de que haya un paso final común se puede especificar para en el caso de que haya que volver hacia atrás, al usar el CTA de siguiente te rediriga a la última página.
	 */
	initialZoom?: number;
	initialSteps?: Steps[];

	/**
	 * Componente que se mostrará después de realizar el envío del formulario.
	 */
	thankYouComponent: JSX.Element;
	lastStep?: Steps;
	multiSteps?: Steps[];
	multiStepScreenIndex?: number;
	/**
	 * Flag para detectar si se está avanzando hacia adelante o hacia atrás (principalmente útil en los multisteps).
	 */
	navigationMovingForward?: boolean;
	nextNumber?: number;
	nextStepCallback?: () => number;
	numberOfScreens: number;
	previousStepCallback?: () => void;
	screenIndex: number;
	/**
	 * Flag que se activa en caso de que haya un problema con la DMS a la hora de grabar la cita.
	 */
	sendDmsFailed?: boolean;
	sendFormCallback?: () => Promise<any>;
	showFinalScreen: boolean;
	/**
	 * Flag para mostrar el calendario o no al volver hacia atrás en cita posventa.
	 */
	showCalendarOnLoad?: boolean;
	step: Steps;
	steps: Steps[];
	stepsHistory: number[];
	stepsLiteralFields: [];
	/**
	 * Flag para controlar en el mapa si el usuario ha seleccionado una concesión, para mostrar directamente el calendario.
	 */
	suggestionIsDealer?: boolean;
	/**
	 * Flag para impedir avanzar al usuario en caso de que seleccione un resultado en las islas canarias para poder redirigir.
	 */
	suggestionIsInCanaryIslands?: boolean;
	/**
	 * Flag que indica si la sugerencia seleccionada es una dirección o no, para mostrar el marker de geolocalización.
	 */
	suggestionIsAddress?: boolean;
	/**
	 * Desactivamos el tracking en algunos journeys
	 */
	trackingIsDisabled?: boolean;
	/**
	 * Flag para ocultar el legal que se muestra por defecto en la pantalla de confirmación.
	 */
	hideLegal?: boolean;
	/**
	 * Elemento para mostrar un legal custom en la pantalla de legales.
	 */
	customLegal?: JSX.Element;
	triggerExitAnimation: boolean;
	userCenter?: Center;
	userIsAuthenticated?: boolean;
	userSuggestion?: Suggestion;
	userGeoLocation?: Suggestion;
	waitForNextStep: boolean;

	//ocultar el close component y el previous step en steps configurados especialmente
	deleteCloseAndPrevious?: boolean;
}

export interface Points {
	id?: number;
	type?: string;
	properties?: {
		parent_cluster_id?: number;
		cluster: boolean;
		concesionId?: string | number;
		dealer?: DealersData;
		installation?: Installation;
		cluster_id?: number;
		distanceFromCenter?: string;
		earliestSlot?: number;
		point_count?: number;
		selected?: boolean;
	};
	geometry?: {
		type?: string;
		coordinates?: [number, number];
	};
}

export interface Center {
	lat: number;
	lng: number;
}

export interface Steps {
	name?: string;
	component?: JSX.Element;
	fields?: JSX.Element;
	fullScreen?: boolean;
	multiStep?: boolean;
	multiStepLastScreen?: boolean;
	outputs?: string[];
	outputsText?: string[];
	ctas?: any[];
	outputsIsStars?: boolean;
	trueOrFalseQuestion?: boolean;
	screenIndex?: number;
	alternateTitle?: JSX.Element;
	title?: JSX.Element;
	isLastStep?: boolean;
	/**
	 * Flag para controlar que se dispare el evento correcto de analítica en la primera carga del layer.
	 */
	isFirstStep?: boolean;
	/**
	 * Flag para ocultar si es necesario que se muestre el componente PreviousStep en el step.
	 */
	hidePreviousStep?: boolean;
	/**
	 * Flag para determinar si el step contiene un select con dropdown NO nativo
	 */
	showOverflow?: boolean;
}

export interface MolecularResponse {
	content: {
		status: string;
		code: string;
		message: string;
		data: object;
	};
	params: {
		fields: {
			COD_LEAD_ID: string;
		};
		headers: object;
	};
	status: {
		code: number;
		result: string;
		route: string;
		time: number;
	};
	success: boolean;
}

export interface OneformBFFResponse {
	content: Availability | any;
	params: any;
	status: {
		code: number;
		duration: number;
		message: string;
		result: string;
		timestamp: string;
	};
}

export interface SalesGroupInfo {
	salesGroupId: string;
	salesGroupName: string;
}

export interface AppadapResponse extends MolecularResponse {
	contentNDPS: {status: string; code: string; message: string; data: object};
}
export interface DomainAttributesValues {
	name: DomainAttributesName;
	value?: string;
}

type DomainAttributesName =
	| 'Id'
	| 'VGED_CommercialName__c'
	| 'ShippingStreet'
	| 'ShippingPostalCode'
	| 'ShippingCity'
	| 'ShippingLatitude'
	| 'ShippingLongitude'
	| 'ShippingStateCode'
	| 'VGED_DealerCode__c'
	| 'KVPSCode__c'
	| 'TXT_V_Installation_Data_Name__c'
	| 'TXT_V_Installation_Code__c';

export interface DomainAttributes {
	attributes: DomainAttributesValues[];
}

export interface DomainResponse {
	content: DomainAttributes[];
	params: {
		token: string;
		domain: string;
	};
	status: {
		code: string;
		result: string;
		route: string;
		time: number;
	};
}

export interface VicciResponse {
	expandedConfiguration: {
		salesgroupKey: string;
	};
}

export interface DealersData {
	id?: number | string;
	name?: string;
	kvps?: string;
	codInstalacion?: string;
	slots?: Slots;
	markerInfo?: {
		dealerInfo?: DealerInfo;
		svcLocation?: SvcLocation;
		id?: string;
	};
	dmsInfo?: DmsInfo;
	availabilityInfo?: null;
}

export interface Slots {
	morning: Slot;
	afternon: Slot;
	evening: Slot;
}

export type SlotsTime = 'morning' | 'afternon' | 'evening';

export interface SvcLocation {
	latitude: number;
	longitude: number;
}

export interface Slot {
	from: number;
	to: number;
	replacementCarServiceAvailable: boolean;
	dayMoment: {code: string; name: string};
	assessorCode: string;
}

export interface DmsInfo {
	name?: string;
	phone?: string;
	email?: string;
	dmsAvailable: boolean;
	dealerId: {
		kvps: string;
		codInstalacion: string;
		brand: string;
	};
	replacementCarServiceAvailable: boolean;
	pickupCarServiceAvailabe: boolean;
	overhaulingServiceAvialable: boolean;
	assessors: Assessors[];
	reference: string;
}

export interface Assessors {
	code: string;
	name: string;
}
export interface DealerInfo {
	dealerId?: {
		kvps?: string;
		codInstalacion?: string;
		brand?: string;
	};
	name?: string;
	phone?: string;
	departmentCode?: string;
	contactName?: string;
	mail?: string;
	city?: string;
	province?: string;
	country?: string;
	address?: string;
	zipCode?: string;
	urls?: string[];
}

export interface Availability {
	content: {
		dealer: DmsInfo;
		calendar: Calendar[];
	};
}

export interface Calendar {
	date: string;
	slots: CalendarSlot[];
}

export interface Day {
	dayNumber: string;
	dayOfTheWeek: string;
	date: string;
	disabled: boolean;
	active: boolean;
}

export interface CalendarSlot {
	hour: string;
	assessor: string;
	from: number;
	to: number;
	replacementCarServiceAvailable: boolean;
	dayMoment: {code: string; name: string};
	assessorCode: string;
	GMT: string;
}

export interface EntregaIdealHorarioResponse {
	date: string;
	turnos: EntrgaIdealTurnos[];
}
interface EntrgaIdealTurnos {
	to: string;
	from: string;
}

export interface Survey {
	dealer?: {
		markerInfo: {};
		dmsInfo: {};
	};
	tc?: string;
	deviceType: string;
	urlOrigin: string;
	url: string;
	formId: string;
	formVersion: string;
	deepLink?: string;
	budget?: string;
	waid?: string;
}

export interface Campaign {
	activo: number;
	salesforce: string;
	dfo: {
		ofertas: string;
	};
}

export interface modelCar {
	id: number;
	name: string;
	title: string;
	brand: string;
	model: string;
}

export interface CarlineBase {
	activo: boolean;
	activoMatch: boolean;
	activoMeasurements: boolean;
	activoOffers: boolean;
	campaign: Campaign;
	capacidadMaletero: number[];
	categorias: Object;
	code: number;
	id: number;
	legalConsumos: string;
	medidas: Object;
	name: string;
	nuevo: boolean;
	precioMinimo?: number;
	precioMinimoModalidad?: string;
	precioMinimoText?: string;
	showroomURL: string;
	text: string;
	title: string;
	plazas: number;
	formularios: {
		testDrive: FormulariosFlagValue;
		presupuesto: FormulariosFlagValue;
		masInformacion: FormulariosFlagValue;
		prospect: FormulariosFlagValue;
	};
}

export interface CarlinesWithSalesgroups extends CarlineBase {
	salesGroups?: Salesgroup[];
}

export interface Salesgroup {
	id: number;
	code: number;
	name: string;
	year: number;
}

export interface Offer {
	anchor: string;
	activo: number;
	carline: {
		id: number;
		nuevo: number;
		code: number;
		name: string;
		title: string;
		text: string;
		salesgroup: {
			id: number;
			code: number;
			name: string;
			year: number;
			model: {
				id: number;
				code: string;
				name: string;
				descripcion: string;
			};
		};
	};
	colores: {
		exterior: Color;
		interior: Color;
	};
	cuota: number;
	cuotaInfo: string;
	cuotaText: string;
	cuotaTipo: string;
	cuotaFinal: string;
	dco: number;
	descripcion: string;
	equipamientos?: OfferEquipments;
	cuotaEntrada: string;
	financiacionName: string;
	financiacionText: string;
	id: number;
	legal: string;
	marketingCode: string;
	mediaAssets: MediaAsset;
	modalidad: string;
	motorizacion: string;
	motor: {
		combustible: string;
		dsg: number;
		fourMotion: number;
		tiptronic: number;
		cambio: string;
	};
	myRenting: boolean;
	plazos: number;
	next: boolean;
	ourRenting: boolean;
	precio: number;
	precioText: string;
	prioridad: number;
	promociones: Promociones[];
	tin: string;
	tae: string;
}

export interface Promociones {
	name: string;
	text: string;
	descripcion: string;
}

export interface Color {
	nombre: string;
	codigo: string;
	hexadecimal: string;
	invertido: boolean;
	atrevido: boolean;
	elegante: boolean;
}
export interface OfferEquipments {
	serie: OfferEquipment[];
	promocional: OfferEquipment[];
	destacados: OfferEquipment[];
}
export interface OfferEquipment {
	equipamiento: {
		titulo: string;
		descripcion: string;
		icono: string;
	};
}

export interface MediaAsset {
	'CarSideview': string;
	'CarStage3-4': string;
}

export type FormularioFlagType = 'testDrive' | 'presupuesto' | 'masInformacion' | 'prospect';
export type FormulariosFlagValue = 0 | 1;

export interface Geometry {
	lat: number;
	lng: number;
	bounds?: google.maps.LatLngBounds;
	viewport?: google.maps.LatLngBounds;
}

export type SelectedTab = 'ubicacion' | 'horario';

export type PassengersTypes = [number, number];
export type FuelTypes = 'Combustible' | 'EléctricoHibrido';
export type LuggageReturn = 'unaMaleta' | 'escapada' | 'unaSemana' | 'unMes' | 'mascota' | 'bici' | 'surf' | 'snow';
export type UsageReturn = 'urban' | 'interurban' | 'long' | 'allterrain';
export type HighlightsReturn =
	| 'atrevido'
	| 'comodo'
	| 'deportivo'
	| 'sostenible'
	| 'elegante'
	| 'seguro'
	| 'sonido'
	| 'tecnologico';

// Necesario para el envío de los formularios de Match
export interface MatchSelectedOptions {
	budget: number;
	passengers: [number, number];
	luggage: number[];
	usage: number[];
	highlights: number[];
	fuel: FuelTypes;
}

export interface ArgumentativeBlock {
	key: string;
	icon?: JSX.Element;
	title?: string;
	boldTitle?: string;
	bodycopy?: string;
	titleRevers?: boolean;
}

export interface CarlineWithOffers extends Preconfiguration {
	ofertas: Offer[];
}

export interface IdLead {
	Id?: string;
	FirstName?: string;
	LastName?: string;
	MiddleName?: string;
	Email?: string;
	MobilePhone?: string;
	VGED_CodeOfInterestCarline__c?: string;
	VGED_ModelOfInterest__c?: string;
	Dealership__c?: string;
}

export interface IdPerson {
	Id?: string;
	FirstName?: string;
	LastName?: string;
	MiddleName?: string;
	PersonEmail?: string;
	PersonMobilePhone?: string;
	PersonMailingAddress?: string; // street, postalCode, stateCode
	LSSI__c; // no se recibe pero de momento no tenemos alternativa
}

export interface IdVehicle {
	Id?: string;
	RCY_BrandList__c?: string; // Marca
	VGED_ModelGroup__c?: string; // Modelo
	Name?: string; // Bastidor (frame)
	VGED_RegistrationPlate__c?: string; // Matricula (plate)
}

export interface IdOrder {
	Id?: string; // salesforce ID
	VGED_ClimaproOrderID__c?: string; // climapro ID
	VGED_CEMDeliveryDate__c?: string;
	RCY_ActualDeliveryDate__c?: string;
}

export interface IdDealer {
	Id?: string;
	VGED_CommercialName__c?: string;
	KVPSCode__c?: string;
	VGED_DealerCode__c?: string;
	VGED_ConcessionDealerCode__c?: string; // Agrupa dealers
	ShippingStreet?: string;
	ShippingPostalCode?: string;
	ShippingCity?: string;
	ShippingLatitude?: string;
	ShippingLongitude?: string;
	ShippingStateCode?: string;
}

export interface IdInstallation extends IdDealer {}
export interface IdConcesion extends IdDealer {}
export interface Installation extends IdDealer {}
export interface Concesion extends IdDealer {}

export interface Cta {
	label: string;
	value: string | boolean;
}

export interface Option {
	label: string;
	value: string;
	info?: string;
}
