import {
	Breakpoints,
	Layout,
	LayoutRowGap,
	Text,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import {
	CarlineSelect,
	DetalleMotivo,
	MultipleCTASelection,
	PantallaIntroduccionStars,
	PantallaNombreApellido,
	RatingStars,
} from 'src/components';
import { CTAsSiNo } from 'src/feature-app/InputsValues/CTAsObjects';
import { RadioTextGroup } from 'src/components/inputs/RadioTextGroup';
import { ConfirmacionCem } from 'src/forms/Cem/ConfirmacionCem';
import { Steps } from 'src/types';
import {
	valueWrongConcesion,
	valueWrongMarca,
	valueWrongModelo,
	valueWrongPersona,
} from '../helpers';
import { FirstStepCEMVentaProducto } from './FirstStepCEMVentaProducto';

function OneElementTemplateFullScreen(props: any) {
	const { element, allowOverflowingContent } = props;

	return (
		<Layout
			allowOverflowingContent
			appearance={{
				[Breakpoints.default]: [
					{ name: '.', columns: 2 },
					{ name: 'a', columns: 20 },
					{ name: '.', columns: 2 },
				],
				[Breakpoints.b960]: [
					{ name: '.', columns: 3 },
					{ name: 'a', columns: 18 },
					{ name: '.', columns: 3 },
				],
				[Breakpoints.b1280]: [
					{ name: '.', columns: 5 },
					{ name: 'a', columns: 14 },
					{ name: '.', columns: 5 },
				],
				[Breakpoints.b1920]: [
					{ name: '.', columns: 6 },
					{ name: 'a', columns: 12 },
					{ name: '.', columns: 6 },
				],
				[Breakpoints.b2560]: [
					{ name: '.', columns: 7 },
					{ name: 'a', columns: 10 },
					{ name: '.', columns: 7 },
				],
			}}
			rowGap={LayoutRowGap.static300}
		>
			{element}
		</Layout>
	);
}

export const getStepsVentaProducto = (isElectrico: boolean, installation: string) => {
	let index = 0;

	const ctaDatosIncorrectos = [
		{ 
			label: 'Marca incorrecta', 
			value: valueWrongMarca.toString() 
		},
		{ 
			label: 'Modelo incorrecto', 
			value: valueWrongModelo.toString() 
		},
		{
			label: 'Punto de venta incorrecto',
			value: valueWrongConcesion.toString(),
		},
		{
			label: 'Persona de contacto incorrecta',
			value: valueWrongPersona.toString(),
		},
	];

	const optionsEnviarConcesion = [
		{
			value: '1',
			label: 'Sí, mis respuestas pueden ser enviadas y analizadas junto a mi nombre y al número de bastidor de mi vehículo, para el alcance descrito anteriormente (el consentimiento puede revocarse en cualquier momento a través de los canales de contacto que se indican a continuación*)',
			info: '*Puedo revocar este consentimiento en cualquier momento sin dar motivos a mi Distribuidor para mis datos personalizados.',
		},
		{
			value: '2',
			label: 'Mis respuestas pueden ser enviadas pero sin mencionar mi nombre y al número de bastidor de mi vehículo',
			info: 'Seleccionando esta opción, el punto de venta no podrá ponerse en contacto contigo en relación a tus comentarios.',
		},
		{
			value: '0',
			label: 'Mis respuestas solo deben ser analizadas de manera anónima',
			info: 'Seleccionando esta opción, tus respuestas serán analizadas de modo agregado junto al resto de respuestas de todos los entrevistados y no se trasladarán a los comentarios abiertos.',
		},
	];

	const firstStep = {
		fields: <FirstStepCEMVentaProducto />,
		screenIndex: index++,
		name: 'isDatosCorrectos',
		alternateTitle: (
			<Text>¿Puedes confirmar que estos datos son correctos?</Text>
		),
		outputs: ['isDatosCorrectos'],
		outputsText: ['isDatosCorrectos'],
		trueOrFalsequestion: true,
	};

	const confirmacionDatos = {
		title: (
			<>
				Por favor,{' '}
				<Text bold>¿puedes confirmarnos qué dato no es correcto?</Text>
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<MultipleCTASelection
						ctaValues={ctaDatosIncorrectos}
						name="datoIncorrecto"
						nextOnClick
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'datoIncorrecto',
		outputs: ['datoIncorrecto'],
		outputsText: ['datoIncorrecto'],
		ctas: ctaDatosIncorrectos,
	};

	const confirmacionConcesion = {
		title: <Text bold>¿Has comprado tu nuevo coche en [Concesionario]?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<MultipleCTASelection
						ctaValues={CTAsSiNo}
						name="confirmacionConcesion"
						nextOnClick
						ctaWidth="81"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'confirmacionConcesion',
		outputs: ['confirmacionConcesion'],
		outputsText: ['confirmacionConcesion'],
	};

	const seleccionModelo = {
		title: <Text bold>¿Cuál es el modelo correcto?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<div style={{ marginBottom: '2px' }}>
						<CarlineSelect isCEMVenta useCarline />
					</div>
				}
			/>
		),
		screenIndex: index++,
		name: 'seleccionModelo',
		outputs: ['seleccionModelo'],
		outputsText: ['seleccionModelo'],
	};

	const inicioRating = {
		title: (
			<>
				A continuación, por favor,{' '}
				<Text bold>
					indica tu nivel de satisfacción con aspectos de tu nuevo coche.
				</Text>
			</>
		),
		fields: (
			<PantallaIntroduccionStars bodyCopy="Valora del 1 al 5, a mayor número de estrellas más satisfacción." />
		),
		screenIndex: index++,
		name: 'inicioRating',
	};

	const preguntaMotivo = (
		<Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>
	);
	const recomendacionMotivo =
		'Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan identificarte a ti o a nuestros empleados.';

	const satisfaccionInterior = {
		title: (
			<Text bold>
				¿Qué puntuación le das al diseño y a los materiales del interior?
			</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionInterior"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionInterior',
		outputs: ['satisfaccionInterior'],
		outputsText: ['satisfaccionInterior'],
		outputsIsStars: true,
	};

	const satisfaccionInteriorMotivo = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionInteriorMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionInteriorMotivo',
		outputs: ['satisfaccionInteriorMotivo'],
		outputsText: ['satisfaccionInteriorMotivo'],
	};

	const satisfaccionExterior = {
		title: <Text bold>¿Qué puntuación le das al diseño exterior?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionExterior"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionExterior',
		outputs: ['satisfaccionExterior'],
		outputsText: ['satisfaccionExterior'],
		outputsIsStars: true,
	};

	const satisfaccionExteriorMotivo = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionExteriorMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionExteriorMotivo',
		outputs: ['satisfaccionExteriorMotivo'],
		outputsText: ['satisfaccionExteriorMotivo'],
	};

	const satisfaccionSoftware = {
		title: <Text bold>¿Cómo valoras el software y la conectividad?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionSoftware"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionSoftware',
		outputs: ['satisfaccionSoftware'],
		outputsText: ['satisfaccionSoftware'],
		outputsIsStars: true,
	};

	const satisfaccionSoftwareMotivo = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionSoftwareMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionSoftwareMotivo',
		outputs: ['satisfaccionSoftwareMotivo'],
		outputsText: ['satisfaccionSoftwareMotivo'],
	};

	const satisfaccionCalidad = {
		title: <Text bold>¿Cómo valoras la calidad de tu Volkswagen?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionCalidad"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionCalidad',
		outputs: ['satisfaccionCalidad'],
		outputsText: ['satisfaccionCalidad'],
		outputsIsStars: true,
	};

	const satisfaccionCalidadMotivo = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionCalidadMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionCalidadMotivo',
		outputs: ['satisfaccionCalidadMotivo'],
		outputsText: ['satisfaccionCalidadMotivo'],
	};

	const satisfaccionConduccion = {
		title: (
			<Text bold>
				¿Qué puntuación le darías a la experiencia de conducción?
			</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionConduccion"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionConduccion',
		outputs: ['satisfaccionConduccion'],
		outputsText: ['satisfaccionConduccion'],
		outputsIsStars: true,
	};

	const satisfaccionConduccionMotivo = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionConduccionMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionConduccionMotivo',
		outputs: ['satisfaccionConduccionMotivo'],
		outputsText: ['satisfaccionConduccionMotivo'],
	};

	const satisfaccionTecnologia = {
		title: (
			<Text bold>
				¿Cómo valoras el funcionamiento de la pantalla y los controles?
			</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionTecnologia"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionTecnologia',
		outputs: ['satisfaccionTecnologia'],
		outputsText: ['satisfaccionTecnologia'],
		outputsIsStars: true,
	};

	const satisfaccionTecnologiaMotivo = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionTecnologiaMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionTecnologiaMotivo',
		outputs: ['satisfaccionTecnologiaMotivo'],
		outputsText: ['satisfaccionTecnologiaMotivo'],
	};

	const satisfaccionInfo = {
		title: <Text bold>¿Qué puntuación le das al sistema de Infotainment?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionInfo"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionInfo',
		outputs: ['satisfaccionInfo'],
		outputsText: ['satisfaccionInfo'],
		outputsIsStars: true,
	};

	const satisfaccionInfoMotivo = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionInfoMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionInfoMotivo',
		outputs: ['satisfaccionInfoMotivo'],
		outputsText: ['satisfaccionInfoMotivo'],
	};

	const satisfaccionMultimedia = {
		title: (
			<Text bold>
				¿Te parece que el sistema multimedia es fácil de manejar?
			</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionMultimedia"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionMultimedia',
		outputs: ['satisfaccionMultimedia'],
		outputsText: ['satisfaccionMultimedia'],
		outputsIsStars: true,
	};

	const satisfaccionMultimediaMotivo = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionMultimediaMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionMultimediaMotivo',
		outputs: ['satisfaccionMultimediaMotivo'],
		outputsText: ['satisfaccionMultimediaMotivo'],
	};

	const satisfaccionPrecio = {
		title: (
			<Text bold>¿Qué te parece la relación calidad-precio de tu coche?</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionPrecio"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionPrecio',
		outputs: ['satisfaccionPrecio'],
		outputsText: ['satisfaccionPrecio'],
		outputsIsStars: true,
	};

	const satisfaccionPrecioMotivo = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionPrecioMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionPrecioMotivo',
		outputs: ['satisfaccionPrecioMotivo'],
		outputsText: ['satisfaccionPrecioMotivo'],
	};

	const satisfaccionGeneral = {
		title: (
			<Text bold>
				¿Y cómo de satisfecho estás con tu nuevo Volkswagen en general?
			</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionGeneral"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionGeneral',
		outputs: ['satisfaccionGeneral'],
		outputsText: ['satisfaccionGeneral'],
		outputsIsStars: true,
	};

	const satisfaccionGeneralMotivo = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionGeneralMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionGeneralMotivo',
		outputs: ['satisfaccionGeneralMotivo'],
		outputsText: ['satisfaccionGeneralMotivo'],
	};

	const satisfaccionExperiencia = {
		title: (
			<Text bold>
				¿Hay algo más que te gustaría comentarnos sobre tu experiencia con tu
				Volkswagen?
			</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionExperiencia"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionExperiencia',
		outputs: ['satisfaccionExperiencia'],
		outputsText: ['satisfaccionExperiencia'],
	};

	const satisfaccionEntrega = {
		title: (
			<Text bold>
				¿Cuál es tu nivel de satisfacción con la ayuda que te hemos dado desde
				la entrega de tu vehículo?
			</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionEntrega"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'satisfaccionEntrega',
		outputs: ['satisfaccionEntrega'],
		outputsText: ['satisfaccionEntrega'],
		outputsIsStars: true,
	};

	const enviarConcesion = {
		title: (
			<>
				Finalmente nos gustaría saber si podemos <Text bold>enviar tus respuestas junto con tu nombre al punto de venta {installation} y el bastidor del vehículo, y puedan usarlos para análisis adicionales.</Text>
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={<RadioTextGroup name="enviarConcesion" options={optionsEnviarConcesion} />}
			/>
		),
		screenIndex: index++,
		name: 'enviarConcesion',
		outputs: ['enviarConcesion'],
		outputsText: ['enviarConcesion'],
		ctas: optionsEnviarConcesion,
	};

	const datosPersonales = {
		title: (
			<>
				Por favor, <Text bold>introduce o comprueba tus datos:</Text>
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<div style={{ paddingBottom: '2px' }}>
						<PantallaNombreApellido />
					</div>
				}
			/>
		),
		screenIndex: index++,
		name: 'datosPersonales',
		outputs: ['name', 'surname', 'secondSurname'],
		outputsText: ['name', 'surname', 'secondSurname'],
	};

	const confirmacion = {
		fields: <ConfirmacionCem />,
		screenIndex: index++,
		name: 'confirmacion',
		outputs: ['confirmacion'],
		outputsText: ['confirmacion'],
	};

	const steps: Steps[] = [
		firstStep,
		confirmacionDatos,
		confirmacionConcesion,
		seleccionModelo,
		inicioRating,
		satisfaccionInterior,
		satisfaccionInteriorMotivo,
		satisfaccionExterior,
		satisfaccionExteriorMotivo,
		satisfaccionSoftware,
		satisfaccionSoftwareMotivo,
		satisfaccionCalidad,
		satisfaccionCalidadMotivo,
		satisfaccionConduccion,
		satisfaccionConduccionMotivo,
		satisfaccionTecnologia,
		satisfaccionTecnologiaMotivo,
		satisfaccionInfo,
		satisfaccionInfoMotivo,
		satisfaccionMultimedia,
		satisfaccionMultimediaMotivo,
		satisfaccionPrecio,
		satisfaccionPrecioMotivo,
		satisfaccionGeneral,
		satisfaccionGeneralMotivo,
		satisfaccionExperiencia,
		satisfaccionEntrega,
		enviarConcesion,
		datosPersonales,
		confirmacion,
	];

	if (isElectrico) {
		const satisfaccionCargaPublica = {
			title: (
				<Text bold>¿Cómo valoras el proceso de carga en lugares públicos?</Text>
			),
			fields: (
				<OneElementTemplateFullScreen
					element={
						<RatingStars
							name="satisfaccionCargaPublica"
							nextOnClick={true}
							textGood="satisfecho/a"
							textBad="insatisfecho/a"
							CTASinRespuesta
						/>
					}
				/>
			),
			screenIndex: index++,
			name: 'satisfaccionCargaPublica',
			outputs: ['satisfaccionCargaPublica'],
			outputsText: ['satisfaccionCargaPublica'],
			outputsIsStars: true,
		};

		const satisfaccionCargaPublicaMotivo = {
			title: preguntaMotivo,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<DetalleMotivo
							required={true}
							name="satisfaccionCargaPublicaMotivo"
							showRecomendation={recomendacionMotivo}
							numberOfMaxLength={300}
							CTALabel="Sin respuesta"
						/>
					}
				/>
			),
			screenIndex: index++,
			name: 'satisfaccionCargaPublicaMotivo',
			outputs: ['satisfaccionCargaPublicaMotivo'],
			outputsText: ['satisfaccionCargaPublicaMotivo'],
		};

		const satisfaccionCargaCasa = {
			title: <Text bold>¿Cómo valoras el proceso de carga en casa?</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<RatingStars
							name="satisfaccionCargaCasa"
							nextOnClick={true}
							textGood="satisfecho/a"
							textBad="insatisfecho/a"
							CTASinRespuesta
						/>
					}
				/>
			),
			screenIndex: index++,
			name: 'satisfaccionCargaCasa',
			outputs: ['satisfaccionCargaCasa'],
			outputsText: ['satisfaccionCargaCasa'],
			outputsIsStars: true,
		};

		const satisfaccionCargaCasaMotivo = {
			title: preguntaMotivo,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<DetalleMotivo
							required={true}
							name="satisfaccionCargaCasaMotivo"
							showRecomendation={recomendacionMotivo}
							numberOfMaxLength={300}
							CTALabel="Sin respuesta"
						/>
					}
				/>
			),
			screenIndex: index++,
			name: 'satisfaccionCargaCasaMotivo',
			outputs: ['satisfaccionCargaCasaMotivo'],
			outputsText: ['satisfaccionCargaCasaMotivo'],
		};

		steps.splice(
			satisfaccionGeneral.screenIndex,
			0,
			satisfaccionCargaPublica,
			satisfaccionCargaPublicaMotivo,
			satisfaccionCargaCasa,
			satisfaccionCargaCasaMotivo,
		); // add electric questions and comments before satisfaccionGeneral
	}

	return steps;
};
