import {
	Breakpoints,
	Container,
	ContainerPadding,
	Layout,
	LayoutRowGap,
	Text,
	TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import {
	CarlineSelect,
	DetalleMotivo,
	MultipleCTASelection,
	MultipleOptionCTAs,
	PantallaIntroduccionStars,
	PantallaNombreApellido,
	RatingStars,
} from 'src/components';
import { CTAsSiNoSinRespuesta } from 'src/feature-app/InputsValues/CTAsObjects';
import { RadioTextGroup } from 'src/components/inputs/RadioTextGroup';
import { ConfirmacionCem } from 'src/forms/Cem/ConfirmacionCem';
import { FirstStepCEM } from 'src/forms/Cem/FirstStepCEM';
import { Steps } from 'src/types';
import {
	valueWrongConcesion,
	valueWrongMarca,
	valueWrongModelo,
	valueWrongPersona,
} from './helpers';

function OneElementTemplateFullScreen(props: any) {
	const { element } = props;
	return (
		<Layout
			allowOverflowingContent
			appearance={{
				[Breakpoints.default]: [
					{ name: '.', columns: 2 },
					{ name: 'a', columns: 20 },
					{ name: '.', columns: 2 },
				],
				[Breakpoints.b960]: [
					{ name: '.', columns: 3 },
					{ name: 'a', columns: 18 },
					{ name: '.', columns: 3 },
				],
				[Breakpoints.b1280]: [
					{ name: '.', columns: 5 },
					{ name: 'a', columns: 14 },
					{ name: '.', columns: 5 },
				],
				[Breakpoints.b1920]: [
					{ name: '.', columns: 6 },
					{ name: 'a', columns: 12 },
					{ name: '.', columns: 6 },
				],
				[Breakpoints.b2560]: [
					{ name: '.', columns: 7 },
					{ name: 'a', columns: 10 },
					{ name: '.', columns: 7 },
				],
			}}
			rowGap={LayoutRowGap.static300}
		>
			{element}
		</Layout>
	);
}

export const getStepsPosVenta = (installation: string, vehicle: string) => {
	
	const ctaMotivoVisita = [
		{
			label: 'Revisión pre-ITV',
			value: '2',
		},
		{
			label: 'Avería circulando con el coche (fue necesario remolcarlo)',
			value: '4',
		},
		{
			label: 'Medida de servicio (excluida la medida de servicio diésel)',
			value: '10',
		},
		{
			label: 'Chapa y pintura',
			value: '6',
		},
		{
			label: 'Cambio de neumáticos',
			value: '3',
		},
		{
			label: 'Actualización de software',
			value: '11',
		},
		{
			label: 'Instalación de accesorios',
			value: '7',
		},
		{
			label: 'Servicio rutinario / de mantenimiento',
			value: '1',
		},
		{
			label: 'Otra visita inesperada (el coche pudo seguir circulando y elegiste tu Sercicio Oficial favorito)',
			value: '5',
		},
		{
			label: 'Otras reparaciones  o reclamaciones (por ejemplo, problemas electrónicos)',
			value: '8',
		},
		// El campo no es required, si no seleccionamos ninguna opción enviamos 99
		/*{
			label: 'Sin respuesta',
			value: '99',
		},*/
	];
	
	const ctaServicioAnteriorIncorrecto = [
		{
			label: 'Sí',
			value: '3',
		},
		{
			label: 'No',
			value: '1',
		},
		{
			label: 'No, pero tendré que volver de nuevo por otra razón diferente (mantenimiento, cambio de neumáticos, etc.)',
			value: '2',
		},
	];
	
	const ctaRazonVolverTaller = [
		{
			label: 'No había recambios disponibles, no eran los correctos o estaban defectuosos',
			value: '1',
		},
		{
			label: 'No hubo tiempo suficiente para terminar el servicio',
			value: '2',
		},
		{
			label: 'El problema no fue resuelto o solo parcialmente',
			value: '3',
		},
		{
			label: 'Los problemas surgieron de nuevo',
			value: '4',
		},
		{
			label: 'Es necesario realizar trabajos adicionales',
			value: '5',
		},
		{
			label: 'Otras razones',
			value: '10',
		},
	];
	
	const optionsPublicarRespuestas = [
		{
			label: 'Sí, mis respuestas pueden ser publicadas sin mi nombre en la Web Oficial Volkswagen y en la de ' + installation,
			value: '1',
			
		},
		{
			label: 'No, mis respuestas no pueden ser publicadas en la Web Oficial Volkswagen y en la de ' + installation,
			value: '2',
			
		},
	];
	
	const ctaDatosIncorrectos = [
		{
			label: 'Marca incorrecta',
			value: valueWrongMarca.toString(),
		},
		{
			label: 'Modelo incorrecto',
			value: valueWrongModelo.toString(),
		},
		{
			label: 'Servicio Oficial',
			value: valueWrongConcesion.toString(),
		},
		{
			label: 'Persona de contacto incorrecta',
			value: valueWrongPersona.toString(),
		},
	];
	
	const optionsEnviarConcesion = [
		{
			value: '3',
			label: 'Sí, mis respuestas pueden ser enviadas y analizadas junto a mi nombre y al número de bastidor de mi vehículo, para el alcance descrito anteriormente (el consentimiento puede revocarse en cualquier momento a través de los canales de contacto que se indican a continuación*)',
			info: '*Puedo revocar este consentimiento en cualquier momento sin dar motivos a mi Distribuidor para mis datos personalizados y a la marca [<a href="mailto:info-datenschutz@volkswagen.de" target="_blank">info-datenschutz@volkswagen.de</a>] para el numero de bastidor. Declaración de Privacidad de datos de VW: <a href="https://datenschutz.volkswagen.de" target="_blank">https://datenschutz.volkswagen.de</a>.',
		},
		{
			value: '2',
			label: 'Mis respuestas pueden ser enviadas pero sin mencionar mi nombre y al número de bastidor de mi vehículo',
			info: 'Seleccionando esta opción, el Servicio Oficial no podrá ponerse en contacto contigo en relación a tus comentarios.',
		},
		{
			value: '0',
			label: 'Mis respuestas solo deben ser analizadas de manera anónima',
			info: 'Seleccionando esta opción, tus respuestas serán analizadas de modo agregado junto al resto de respuestas de todos los entrevistados y no se trasladarán a los comentarios abiertos.',
		},
	];
	
	let index = 0;
	const stepsVenta: Steps[] = [
	{
		fields: <FirstStepCEM />,
		screenIndex: index++,
		name: 'DatosCorrectos',
		alternateTitle: (
			<Text>
				¿Puedes confirmar que estos datos son correctos y que eres la persona
				que mejor nos puede informar sobre la última visita al taller?
			</Text>
		),
		outputs: ['datosCorrectos'],
		outputsText: ['datosCorrectos'],
	},
	{
		title: (
			<>
				Por favor, <Text bold>¿puedes confirmar qué dato no es correcto?</Text>
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<MultipleCTASelection
						ctaValues={ctaDatosIncorrectos}
						name="datosIncorrectos"
						nextOnClick
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'DatosIncorrectos',
		outputs: ['datosIncorrectos'],
		outputsText: ['datosIncorrectos'],
		ctas: ctaDatosIncorrectos,
	},
	{
		title: <Text bold>¿Cuál es el modelo correcto?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<div style={{ marginBottom: '2px' }}>
						<CarlineSelect />
					</div>
				}
			/>
		),
		screenIndex: index++,
		name: 'Modelo',
		outputs: ['modelo'],
		outputsText: ['modelo'],
	},
	{
		title: (
			<>
				A continuación,{' '}
				<Text bold>
					indica tu nivel de satisfacción con cada uno de estos aspectos
					relacionados con tu visita al Servicio Oficial.
				</Text>
			</>
		),
		fields: (
			<PantallaIntroduccionStars bodyCopy="Valora del 1 al 5, siendo 1 insatisfecho/a y 5 totalmente satisfecho/a." />
		),
		screenIndex: index++,
		name: 'Explicacion',
	},
	{
		title: <Text bold>El trato recibido</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionTrato"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'SatisfaccionTrato',
		outputs: ['satisfaccionTrato'],
		outputsText: ['satisfaccionTrato'],
		outputsIsStars: true,
	},
	{
		title: (
			<Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionTratoMotivo"
						showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan identificarte a ti o a nuestros empleados."
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'SatisfaccionTratoMotivo',
		outputs: ['satisfaccionTratoMotivo'],
		outputsText: ['satisfaccionTratoMotivo'],
	},
	{
		title: (
			<>
				<Text bold>La organización</Text> de {installation}
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<div style={{ paddingTop: '8px' }}>
						<Text appearance={TokenTextAppearance.copy200} staticSize>
							Por ejemplo: el establecimiento de la cita, tiempos de espera en
							el taller, la fecha de entrega o el tiempo que estuvo el coche en
							el taller.{' '}
						</Text>
						<RatingStars
							name="satisfaccionOrganizacion"
							nextOnClick={true}
							textGood="satisfecho/a"
							textBad="insatisfecho/a"
							CTASinRespuesta
						/>
					</div>
				}
			/>
		),
		screenIndex: index++,
		name: 'SatisfaccionOrganizacion',
		outputs: ['satisfaccionOrganizacion'],
		outputsText: ['satisfaccionOrganizacion'],
		outputsIsStars: true,
	},
	{
		title: (
			<Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionOrganizacionMotivo"
						showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan identificarte a ti o a nuestros empleados."
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'SatisfaccionOrganizacionMotivo',
		outputs: ['satisfaccionOrganizacionMotivo'],
		outputsText: ['satisfaccionOrganizacionMotivo'],
	},
	{
		title: (
			<>
				<Text bold>La calidad del trabajo realizado</Text> en tu {vehicle}
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionTrabajoRealizado"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'SatisfaccionTrabajoRealizado',
		outputs: ['satisfaccionTrabajoRealizado'],
		outputsText: ['satisfaccionTrabajoRealizado'],
		outputsIsStars: true,
	},
	{
		title: (
			<Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionTrabajoRealizadoMotivo"
						showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan identificarte a ti o a nuestros empleados."
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'SatisfaccionTrabajoRealizadoMotivo',
		outputs: ['satisfaccionTrabajoRealizadoMotivo'],
		outputsText: ['satisfaccionTrabajoRealizadoMotivo'],
	},
	{
		title: (
			<>
				Pensando en tu última visita a {installation},{' '}
				<Text bold>
					¿cuál es tu nivel de satisfacción con nuestros servicios en general?
				</Text>
			</>
		),
		alternateTitle: (
			<Text bold>
				¿Cuál es tu nivel de satisfacción con nuestros servicios en general?
			</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<div style={{ paddingTop: '8px' }}>
						<Text appearance={TokenTextAppearance.copy200} staticSize>
							Pensando en tu última visita.
						</Text>
						<RatingStars
							name="satisfaccionConcesion"
							nextOnClick={true}
							textGood="satisfecho/a"
							textBad="insatisfecho/a"
							CTASinRespuesta
						/>
					</div>
				}
			/>
		),
		screenIndex: index++,
		name: 'SatisfaccionConcesion',
		outputs: ['satisfaccionConcesion'],
		outputsText: ['satisfaccionConcesion'],
		outputsIsStars: true,
	},
	{
		title: (
			<Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionConcesionMotivo"
						showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
			identificarte a ti o a nuestros empleados."
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'SatisfaccionConcesionMotivo',
		outputs: ['satisfaccionConcesionMotivo'],
		outputsText: ['satisfaccionConcesionMotivo'],
	},
	{
		title: (
			<Text bold>
				¿Hay algún aspecto con el que no estuvieras satisfecho y que no hayas
				mencionado aún?
			</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="aspectoSatisfaccionMotivo"
						showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan identificarte a ti o a nuestros empleados."
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'AspectoSatisfaccionMotivo',
		outputs: ['aspectoSatisfaccionMotivo'],
		outputsText: ['aspectoSatisfaccionMotivo'],
	},
	{
		title: (
			<>
				<Text bold>¿Tienes que volver a pasar por taller</Text> por un servicio
				realizado de manera incorrecta o incompleta durante la última visita?
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<MultipleCTASelection
						ctaValues={ctaServicioAnteriorIncorrecto}
						name="servicioAnteriorIncorrecto"
						nextOnClick
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'ServicioAnteriorIncorrecto',
		outputs: ['ServicioAnteriorIncorrecto'],
		outputsText: ['servicioAnteriorIncorrecto'],
		ctas: ctaServicioAnteriorIncorrecto,
	},
	{
		title: (
			<>
				<Text bold>¿Cuál es la razón</Text> por la que tienes que volver a pasar
				por taller?
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<>
						<MultipleCTASelection
							ctaValues={ctaRazonVolverTaller}
							name="razonVolverTaller"
							nextOnClick
						/>
					</>
				}
			/>
		),
		screenIndex: index++,
		name: 'RazonVolverTaller',
		outputs: ['RazonVolverTaller'],
		outputsText: ['razonVolverTaller'],
		ctas: ctaRazonVolverTaller,
	},
	{
		title: (
			<>
				¿Cuál es tu nivel de satisfacción con la{' '}
				<Text bold>gestión que hizo el Servicio Oficial de tu tiempo?</Text>
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<div style={{ paddingTop: '8px' }}>
						<Text appearance={TokenTextAppearance.copy200} staticSize>
							El tiempo total desde que entró y desde que salió tu vehículo del
							taller.
						</Text>
						<RatingStars
							name="tiempoSatisfaccion"
							nextOnClick={true}
							textGood="satisfecho/a"
							textBad="insatisfecho/a"
							CTASinRespuesta
						/>
					</div>
				}
			/>
		),
		screenIndex: index++,
		name: 'TiempoSatisfaccion',
		outputs: ['tiempoSatisfaccion'],
		outputsText: ['tiempoSatisfaccion'],
		outputsIsStars: true,
	},
	{
		title: (
			<>
				Después de tu última visita,{' '}
				<Text bold>
					¿{installation} se puso en contacto contigo para comprobar tu nivel de
					satisfacción con el servicio recibido?
				</Text>
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<MultipleCTASelection
						ctaValues={CTAsSiNoSinRespuesta}
						name="contactoSatisfaccion"
						nextOnClick
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'ContactoSatisfaccion',
		outputs: ['ContactoSatisfaccion'],
		outputsText: ['contactoSatisfaccion'],
	},
	{
		title: (
			<>
				¿Cuál o cuáles de los aspectos de la siguiente lista fueron{' '}
				<Text bold> el motivo de tu última visita al Servicio Oficial?</Text>
				<Container padding={{ top: ContainerPadding.dynamic0100 }}>
					<Text appearance={TokenTextAppearance.copy200} staticSize>
						Puedes elegir tres opciones
					</Text>
				</Container>
			</>
		),
		alternateTitle: (
			<>
				¿Cuál o cuáles de los aspectos de la siguiente lista fueron{' '}
				<Text bold> el motivo de tu última visita al Servicio Oficial?</Text>
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<MultipleOptionCTAs
						ctaValues={ctaMotivoVisita}
						name="motivoVisita"
						minSelected={0}
						maxSelected={3}
						ignoreHandleClick={true}
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'MotivoVisita',
		outputs: ['motivoVisita'],
		outputsText: ['motivoVisita'],
		ctas: ctaMotivoVisita,
	},
	{
		title: (
			<>
				<Text bold>Pensando en tu actual {vehicle},</Text> ¿cuál es tu nivel de
				satisfacción?
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionVehiculo"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'SatisfaccionVehiculo',
		outputs: ['satisfaccionVehiculo'],
		outputsText: ['satisfaccionVehiculo'],
		outputsIsStars: true,
	},
	{
		title: (
			<>
				Por favor, háblanos de tu{' '}
				<Text bold>experiencia con tu nuevo {vehicle}</Text>
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionVehiculoMotivo"
						showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan identificarte a ti o a nuestros empleados."
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: index++,
		name: 'SatisfaccionVehiculoMotivo',
		outputs: ['satisfaccionVehiculoMotivo'],
		outputsText: ['satisfaccionVehiculoMotivo'],
	},
	{
		title: (
			<>
				Para finalizar, <Text bold>nos gustaría saber si podemos enviar tus respuestas, junto con tu nombre y el número de bastidor de tu vehículo, al Servicio Oficial {installation} para que puedan usarlos para análisis de producto adicionales. Puedes encontrar más información sobre el trato de tus datos personales en el link disponible al finalizar la encuesta.</Text>
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={<RadioTextGroup name="enviarConcesion" options={optionsEnviarConcesion} />}
			/>
		),
		screenIndex: index++,
		name: 'EnviarConcesion',
		outputs: ['enviarConcesion'],
		outputsText: ['enviarConcesion'],
		ctas: optionsEnviarConcesion,
	},
	{
		title: (
			<>
				Por favor, <Text bold>introduce o comprueba tus datos:</Text>
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<div style={{ marginBottom: '2px' }}>
						<PantallaNombreApellido />
					</div>
				}
			/>
		),
		screenIndex: index++,
		name: 'DatosPersonales',
		outputs: ['name', 'surname', 'secondSurname'],
		outputsText: ['name', 'surname', 'secondSurname'],
	},
	{
		title: (
			<Text bold>
				¿Podríamos publicar tus respuestas en la web oficial de Volkswagen y en
				la de {installation} sin mencionar tu nombre?
			</Text>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<div style={{ paddingTop: '8px' }}>
						<Text appearance={TokenTextAppearance.copy200} staticSize>
							Para compartir tu experiencia con otros clientes.
						</Text>
						<RadioTextGroup name="publicarRespuestas" options={optionsPublicarRespuestas} />
					</div>
				}
			/>
		),
		screenIndex: index++,
		name: 'PublicarRespuestas',
		outputs: ['publicarRespuestas'],
		outputsText: ['publicarRespuestas'],
		ctas: optionsPublicarRespuestas,
	},
	{
		fields: <ConfirmacionCem />,
		screenIndex: index++,
		name: 'Confirmacion',
		outputs: ['confirmacion'],
		outputsText: ['confirmacion'],
	},
	];

	return stepsVenta;
};
