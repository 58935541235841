import { useFeatureServices } from 'src/feature-app';

export function useNavigate() {
	const navigationService = useFeatureServices()['navigation-service'];

	const navigateTo = (pageId: string) => {
		const target = navigationService.navigateTo(pageId, {});
		if (target) {
			target.push();
			return true;
		} else {
			return false;
		}
	};

	return {
		navigateTo,
	};
}
